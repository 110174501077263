import { StyleSheet } from "aphrodite";
import { hexToRgbA } from "util/styleUtil";
import typography from "defaults/typography";
import skins from "defaults/skins"

const getSkin = theme => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		introFooterContainer: {
			backgroundColor: skin.white,
			boxShadow: `0px -4px 12px rgba(58, 23, 67, 0.2)`
		},
		introText: {
			...myTypography.body1,
			fontFamily: theme.fontFamily.titleFamily,
			fontSize: "1.5em",
			fontWeight: 600,
			paddingRight: "1.098vw",
			lineHeight: 1,
			color: skin.white
		},
		transcriptText: {
			...myTypography.body1,
			color: skin.white,
			fontSize: '1em',
			fontWeight: '500'
		},
		transcriptContent: {
			backgroundColor: hexToRgbA(skin.black, 0.6),
			boxShadow: `0px 4px 4px 0px ${hexToRgbA(skin.black, 0.1)}`,
			borderRadius: "8px"
		},
		sprintNumber: {
			...myTypography.body1,
			backgroundColor: "#333333",
			borderRadius: "2px",
			fontWeight: 600,
			fontSize: "0.875em",
			lineHeight: "20px"
		},
		sprintContainer: {
			backgroundColor: hexToRgbA(skin.black, 0.6),
			boxShadow: `0px 4px 4px 0px ${hexToRgbA(skin.black, 0.6)}`,
			borderRadius: "4px"
		},
		cardContainer: {
			border: `1px solid #972082`,
			borderRadius: `4px`,
			boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.09), 0px 3px 4px rgba(0, 0, 0, 0.25)`,
			background: skin.white
		},
		cardTag: {
			background: skin.grayColor8
		},
		priorityContainer: {
			borderRadius: `2px`,
			fontFamily: skins.fontFamily.bodyFamily
		},
		cardTagText: {
			...myTypography.card_text
		},
		cardContent: {
			...myTypography.card_text
		},
		cardDescription: {
			borderTop: `1px solid #DADADA`,
			color: skin.black
		},
		storyPointContainer: {
			fontFamily: skins.fontFamily.titleFamily
		}
	});
};

export default getSkin;
