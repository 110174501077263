import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
// import typography from 'defaults/typography';

const getSkin = (theme) => {
    // const globalProfiles = theme.globalProfiles;
	// const skin = globalProfiles.palette;
	// const myTypography = typography(theme);

    return StyleSheet.create({
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			backdropFilter: 'blur(2px)'
		}
	});
}

export default getSkin;