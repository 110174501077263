import React from 'react';
import './tutorialSequence.css';
import teamMembersStyle from 'components/metricsHeader/teamMembers/teamMembers.module.sass';
import customerStyle from 'components/metricsHeader/customers/customers.module.sass'
import sprintCompStyle from 'components/metricsHeader/sprintComponent/sprintComponent.module.sass';
import StepNext from '../../svgComponents/stepNext';
import StepBack from '../../svgComponents/stepBack';
import skins from 'defaults/skins';
import FilledButton from '../../commonComponents/buttons/filledButton/FilledButton.jsx';
import SpotlightPortal from './SpotLightPortal.jsx';


const getSimPlanningSteps = (step1Header, step1Text, step2Header, step2Text) => {
    return [
        {
            target: "." + teamMembersStyle["tm-container"], //get first mail
            content: (
                <div className='step-5-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    <div className='step-5-container-text-box'>
                        <div className='step-5-container-header'>{step1Header}</div>
                        <div className='step-5-container-text'>{step1Text}</div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "." + customerStyle["customers-container"], //get first mail
            content: (
                <div className='step-1-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    <div className='step-1-container-text-box'>
                        <div className='step-1-container-header'>{step2Header}</div>
                        <div className='step-1-container-text'>{step2Text}</div>
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: "bottom",
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        }
    ]
};

const getSprintPlanningSteps = (stepMVPTutorial, stepWeeks, stepTime, stepLeaderBoard, stepMosco, step1Text, stepMetricsTutorial, step2Text, step3Text, stepButton, stepNext, stepPrev, handleGotItClick) => {
    return [
        {
            target: ".metric-tutorial-mvp",
            content: (
                <div className='step-1-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    <div className='step-1-container-text'>
                        {stepMVPTutorial}
                    </div>
                    <div className='step-container-next' onClick={stepNext} style={{ cursor: 'pointer' }}>
                        <StepNext />
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                },
            }
        },
        {
            target: ".sim-progress",
            content: (
                <div className='step-1-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    <div className='step-1-container-text'>
                        {stepWeeks}
                    </div>
                    <div className='step-arrow'>
                        <div className='step-1-container-arrow' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div>
                        <div className='step-1-container-arrow' onClick={stepNext} style={{ cursor: 'pointer' }}>
                            <StepNext />
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: ".sprint-timer",
            content: (
                <div className='step-1-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily,
                    fontSize: '1.171vw' }}>
                    <div className='step-1-container-text'>
                        {stepTime}
                    </div>
                    <div className='step-arrow'>
                        <div className='step-1-container-arrow' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div>
                        <div className='step-1-container-arrow' onClick={stepNext} style={{ cursor: 'pointer' }}>
                            <StepNext />
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: ".sprint-leaderboard",
            content: (
                <div className='step-1-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    <div className='step-1-container-text'>
                        {stepLeaderBoard}
                    </div>
                    <div className='step-arrow'>
                        <div className='step-1-container-arrow' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div>
                        <div className='step-1-container-arrow' onClick={stepNext} style={{ cursor: 'pointer' }}>
                            <StepNext />
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: ".sprint-mosco",
            content: (
                <div className='step-6-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily,
                    fontSize: '1.171vw' }}>
                    <div className='step-1-container-text'>
                        {stepMosco}
                    </div>
                    <div className='step-arrow'>
                        <div className='step-1-container-arrow' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div>
                        <div className='step-1-container-arrow' onClick={stepNext} style={{ cursor: 'pointer' }}>
                            <StepNext />
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "." + sprintCompStyle["sprint-container"],
            content: (
                <div className='step-1-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    <div className='step-1-container-text'>
                        {step1Text}
                    </div>
                    <div className='step-arrow'>
                        <div className='step-1-container-arrow' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div>
                        <div className='step-1-container-arrow' onClick={stepNext} style={{ cursor: 'pointer' }}>
                            <StepNext />
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: ".metric-tutorial",
            content: (
                <div className='step-1-container'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    <div className='step-1-container-text'>
                        {stepMetricsTutorial}
                    </div>
                    <div className='step-arrow'>
                        <div className='step-1-container-arrow' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div>
                        <div className='step-1-container-arrow' onClick={stepNext} style={{ cursor: 'pointer' }}>
                            <StepNext />
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#task-card-0",
            content: (
                <div>
                    <div className='step-2-container'
                    style={{ fontFamily: skins.fontFamily.bodyFamily ,
                        fontSize: '1.171vw'}}>
                        <div className='step-2-container-text'
                            dangerouslySetInnerHTML={{ __html: step2Text }}>

                        </div>
                        {/* <div className='step-back' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div> */}
                    </div>
                    <div className='step-button'>
                        {/* <button onClick={handleGotItClick} style={{ cursor: 'pointer', fontSize: '1em', fontWeight: '600', width: 'auto', height: '5.208vh', lineHeight: '2.865vh' }}>
                            {stepButton}
                        </button> */}
                        <FilledButton
						textLabel={stepButton}
						clickFunction={handleGotItClick}
					/>
                    </div>
                    <div className='tool-tip'>
                        <div className='step-2-container'
                        style={{ fontFamily: skins.fontFamily.bodyFamily ,
                            fontSize: '1.171vw'}}>
                            <div className='step-2-container-text'
                                dangerouslySetInnerHTML={{ __html: step3Text }}>
                            </div>
                        </div>
                        <div className='step-back' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                            <StepBack />
                        </div>
                    </div>
                    <span className="arrow">
                        <svg width="19" height="15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <polygon points="19,15 19,0 0,7.5" fill="white"></polygon>
                        </svg>
                    </span>
                    <SpotlightPortal targetSelector=".react-joyride__overlay">
                        <div className="spotlight"></div>
                    </SpotlightPortal>
                </div>
            ),
            textAlign: "center",
            placement: "right",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },

    ]
}

const getSprintExecutionSteps = (step1Text, step2TextPart1,step2TextPart2, step3Text, step4Text,cponame, cpotitle, tutorialImage, stepNext,stepPrev) => {
    return [
        {
            target: "#actions-list-container", //get first mail
            content: (
                <div className='step-1-container-action-list'
                    style={{ fontFamily: skins.fontFamily.bodyFamily ,
                        fontSize: '1.171vw'}}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-action-list'>
                        <div className='step-2-container-text-action-list' dangerouslySetInnerHTML={{ __html: step1Text }}></div>
                    </div>
                    <div className='step-container-next-action-list' onClick={stepNext} style={{ cursor: 'pointer' }}>
                        <StepNext />
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "left",
            disableBeacon: true,
            disableScrolling: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            },
            floaterProps:{
                styles:{
                    floaterOpening:{
                        top:'-17.8vh'
                    }
                }
            }
        },
        {
            target: "#label_action_4_title", //get first mail
            content: (
                <div className='step-1-container-action'
                    style={{ fontFamily: skins.fontFamily.bodyFamily ,
                        fontSize: '1.171vw'}}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-action-list'>
                        <div className='step-2-container-text-action-list' 
                        dangerouslySetInnerHTML={{ 
                            __html: step2TextPart1,
                             }}>
                        </div>
                        <div className='step-2-container-text-action-list' 
                        style={{fontWeight:600}}
                        dangerouslySetInnerHTML={{ 
                            __html: step2TextPart2,
                             }}>
                        </div>
                    </div>
                    <div className='step-container-next-action-list' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                        <StepBack />
                    </div>
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableOverlayClose: true,
            disableScrolling: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: "left",
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#popover-container", //get first mail
            content: (
                <div className='step-1-container-single-action'
                    style={{ fontFamily: skins.fontFamily.bodyFamily ,
                        fontSize: '1.171vw'}}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-action-list'>
                        <div className='step-2-container-text-action-list' 
                            dangerouslySetInnerHTML={{ 
                            __html: step3Text,
                             }}>
                        </div>
                    </div>
                    {/* <div className='step-container-next-action-list' onClick={stepNext} style={{ cursor: 'pointer' }}>
                        <StepNext />
                    </div> */}
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableOverlayClose: true,
            disableScrolling: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: "left",
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#action-response-container", //get first mail
            content: (
                <div className='step-1-container-action-response'
                    style={{ fontFamily: skins.fontFamily.bodyFamily ,
                        fontSize: '1.171vw'}}>
                    <div className='step-2-container-text-box-action-response'>
                        <div className='step-2-container-text-action-list' 
                            dangerouslySetInnerHTML={{ 
                            __html: step4Text,
                             }}>
                        </div>
                    </div>
                    {/* <div className='step-container-next-action-list' onClick={stepNext} style={{ cursor: 'pointer' }}>
                        <StepNext />
                    </div> */}
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableOverlayClose: true,
            disableScrolling: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: "top",
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        }
    ]
}

const getBlockerSteps = (step1Text,step2Text,step3Text,step4Text) => {
    return [
        {
            target: "#task-card-blocker", //get first mail
            content: (
                <div>
                    <div className='step-4-container'
                    style={{ fontFamily: skins.fontFamily.bodyFamily , fontSize: '1.171vw'}}>
                        <div className='step-2-container-text-box'>
                            <div className='step-blocker-container-text' dangerouslySetInnerHTML={{ __html: step1Text }}></div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "right",
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#story-popup-container", //get first mail
            content: (
                <div>
                    <div className='step-2-blocker-container'
                    style={{ fontFamily: skins.fontFamily.bodyFamily , fontSize: '1.171vw'}}>
                        <div className='step-2-container-text-box'>
                            <div className='step-blocker-container-text' dangerouslySetInnerHTML={{ __html: step2Text }}></div>
                        </div>
                    </div>
                    <div className='tooltip-blocker'>
                        <div className='step-2-blocker-container'
                        style={{ fontFamily: skins.fontFamily.bodyFamily , fontSize: '1.171vw'}}>
                            <div className='step-2-container-text-box'>
                                <div className='step-blocker-container-text' dangerouslySetInnerHTML={{ __html: step3Text }}></div>
                            </div>
                        </div>
                    </div>
                    <span className='arrow-blocker'>
                        <svg width="19" height="15" version="1.1" xmlns="http://www.w3.org/2000/svg"><polygon points="19,15 19,0 0,7.5" fill="white"></polygon></svg>
                    </span>
                </div>
            ),
            textAlign: "center",
            placement: "left-end",
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#action-response-container", 
            content: (
                <div className='step-1-container-action-response'
                    style={{ fontFamily: skins.fontFamily.bodyFamily ,
                        fontSize: '1.171vw'}}>
                    <div className='step-2-container-text-box-blocker-response'>
                        <div className='step-2-container-text-action-list' 
                            dangerouslySetInnerHTML={{ 
                            __html: step4Text,
                             }}>
                        </div>
                    </div>
                    {/* <div className='step-container-next-action-list' onClick={stepNext} style={{ cursor: 'pointer' }}>
                        <StepNext />
                    </div> */}
                </div>
            ),
            textAlign: "center",
            disableBeacon: true,
            disableOverlayClose: true,
            disableScrolling: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: "top",
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        }
    ]
}

const getSprintPlanningFinalSprintSteps = (simProgressStepDesc, stepSprintDesc, cponame, cpotitle, tutorialImage, stepNext, stepPrev) => {
    return [
        {
            target: ".sim-progress",
            content: (
                <div className='step-1-container-final'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='text-box-final-sprint'>
                        <div className='step-2-container-text-report'>
                            {simProgressStepDesc}
                            <div className='step-1-container-arrow-report' onClick={stepNext} style={{ cursor: 'pointer' }}>
                                <StepNext />
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "." + sprintCompStyle["sprint-container"],
            content: (
                <div className='step-1-container-final1'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='text-box-final-sprint'>
                        <div className='step-2-container-text-report'>
                            {stepSprintDesc}
                            <div className='step-1-container-arrow-report-back' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                                <StepBack />
                            </div>
                            <div className='step-1-container-arrow-report' onClick={stepNext} style={{ cursor: 'pointer' }}>
                                <StepNext />
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        }
    ]
}

const getSprintReportSteps = (simVelocity, sprintAcuracyStepDesc, simEfficiency, simRetro, simUnfinished, simMVPPopupDesc, cponame, cpotitle, tutorialImage, stepNext, stepPrev) => {
    return [
        {
            target: "#metric-velocity",
            content: (
                <div className='step-1-container-velocity'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'
                }}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-action-list'>
                        <div className='step-2-container-text-report'>
                            {simVelocity}
                            <div className='step-arrow-report'>
                                {/* <div className='step-1-container-arrow-report' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                                    <StepBack />
                                </div> */}
                                <div className='step-1-container-arrow-report-velocity' onClick={stepNext} style={{ cursor: 'pointer' }}>
                                    <StepNext />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "right",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#metric-efficiency",
            content: (
                <div className='step-1-container-efficiency'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'
                    }}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-report'>
                        <div className='step-2-container-text-report'>
                            <div style={{width:"30vw"}}>{simEfficiency}</div>
                            {/* <div className='step-arrow-report'> */}
                            <div className='step-1-container-arrow-report-back' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                                <StepBack />
                            </div>
                            <div className='step-1-container-arrow-report' onClick={stepNext} style={{ cursor: 'pointer' }}>
                                <StepNext />
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "right",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#retro",
            content: (
                <div className='step-1-container-unfinished'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'
                }}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-retro'>
                        <div className='step-2-container-text-report'>
                            <div style={{width:"30vw"}}
                            dangerouslySetInnerHTML={{__html: simRetro}}>
                            </div>
                            <div className='step-1-container-arrow-report-back' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                                <StepBack />
                            </div>
                            <div className='step-1-container-arrow-report-velocity' onClick={stepNext} style={{ cursor: 'pointer' }}>
                                <StepNext />
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "left",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#unfinished-container",
            content: (
                <div className='step-1-container-unifinished'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'
                }}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-retro'>
                        <div className='step-2-container-text-report'>
                            <div style={{width:"28vw"}}>{simUnfinished}</div>
                            <div className='step-1-container-arrow-report-back' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                                <StepBack />
                            </div>
                            <div className='step-1-container-arrow-report-velocity' onClick={stepNext} style={{ cursor: 'pointer' }}>
                                <StepNext />
                            </div>
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "right",
            disableBeacon: true,
            disableOverlayClose: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        },
        {
            target: "#sim-mvp",
            content: (
                <div className='step-1-container-mvp'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                    {/* <div className='step-2-container-img-box'>
                        <img src={tutorialImage}
                            style={{ width: '100%', height: '36px' }}
                        />
                        <div className='img-text'>{cponame}</div>
                        <div className='img-text'>{cpotitle}</div>
                    </div> */}
                    <div className='step-2-container-text-box-report'>
                        <div className='step-2-container-text-report'>
                            <div style={{width:"30vw"}}>{simEfficiency}</div>
                            {/* <div className='step-arrow-report'> */}
                            <div className='step-1-container-arrow-report-mvp-back' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                                <StepBack />
                            </div>
                            <div className='step-1-container-arrow-report-mvp' onClick={stepNext} style={{ cursor: 'pointer' }}>
                                <StepNext />
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            ),
            textAlign: "center",
            placement: "bottom",
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000
                }
            }
        }
    ]
}

const getEventSteps1 = (step1Text, step2Text, step3Text, stepNext, stepPrev) => {
    return [
    {
        target: "#event-actions-container",
        content: (
            <div className='step-1-container-event'
            style={{ fontFamily: skins.fontFamily.bodyFamily ,
                fontSize: '1.171vw'
            }}>
                <div className='step-2-container-text-box-retro'>
                    <div className='step-2-container-text-report'
                    dangerouslySetInnerHTML={{__html: step1Text}}>
                    </div>
                    <div className='step-container-event' onClick={stepNext} style={{ cursor: 'pointer'}}>
                        <StepNext />
                    </div>
                </div>
            </div>
        ),
        textAlign: "center",
        placement: "top",
        disableBeacon: true,
        disableOverlayClose: true,
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },
    {
        target: "#event-actions-container",
        content: (
            <div className='step-2-container-event'
            style={{ fontFamily: skins.fontFamily.bodyFamily ,
                fontSize: '1.171vw'
            }}>
                <div className='step-2-container-text-box-retro'>
                    <div 
                    dangerouslySetInnerHTML={{__html: step2Text}}>
                    </div>
                    <div className='step-container-event' onClick={stepPrev} style={{ cursor: 'pointer' }}>
                        <StepBack />
                    </div>
                </div>
            </div>
        ),
        textAlign: "center",
        placement: "top-start",
        spotlightClicks: true,
        disableBeacon: true,
        disableOverlayClose: true,
        styles: {
            options: {
                zIndex: 10000
            }
        },
        floaterProps:{
            styles:{
                floaterOpening:{
                    left:'42vw'
                }
            }
        }
    },
    {
        target: "#action-response-container", 
        content: (
            <div className='step-1-container-action-response'
                style={{ fontFamily: skins.fontFamily.bodyFamily ,
                    fontSize: '1.171vw'}}>
                <div className='step-2-container-text-box-blocker-response'>
                    <div className='step-2-container-text-action-list' 
                        dangerouslySetInnerHTML={{ 
                        __html: step3Text,
                         }}>
                    </div>
                </div>
                {/* <div className='step-container-next-action-list' onClick={stepNext} style={{ cursor: 'pointer' }}>
                    <StepNext />
                </div> */}
            </div>
        ),
        textAlign: "center",
        disableBeacon: true,
        disableOverlayClose: true,
        disableScrolling: true,
        hideFooter: true,
        spotlightClicks: true,
        placement: "top",
        styles: {
            options: {
                zIndex: 10000
            }
        }
    }

    ]
}

const getEventSteps2 = (step1Text ) => {
    return [
    {
        target: "#event-actions-container",
        content: (
            <div className='step-1-container-event'
            style={{ fontFamily: skins.fontFamily.bodyFamily ,
                fontSize: '1.171vw'
            }}>
                <div className='step-2-container-text-box-retro'>
                    <div className='step-2-container-text-report'
                    dangerouslySetInnerHTML={{__html: step1Text}}>
                    </div>
                </div>
            </div>
        ),
        textAlign: "center",
        placement: "top",
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        styles: {
            options: {
                zIndex: 10000
            }
        }
    },
    ]
}

export default { getSimPlanningSteps, getSprintPlanningSteps, getSprintExecutionSteps, getBlockerSteps, getSprintPlanningFinalSprintSteps, getSprintReportSteps, getEventSteps1, getEventSteps2 }