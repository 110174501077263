import React from "react";
import styles from "./stepBack.module.sass";
import applyWrappers from "wrappers/ComponentWrapper";
import { checkIfPresent } from "util/utilFunctions";

const StepBack = props => {
	const skin = props.skinGuide.globalProfiles.palette;
	let color = skin.black;
	if (checkIfPresent(props.svgColor)) {
		color = props.svgColor;
	}
	return (
		<div styleName="main-component">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
                <rect width="24" height="24" rx="12" fill="#978F80" />
				<path
					d="M8.25 15L5.25 12M5.25 12L8.25 9M5.25 12L18.75 12"
					stroke="black"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
};

export default applyWrappers(StepBack, styles);
