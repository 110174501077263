import React from 'react';
import styles from './storyPopup.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import Close from 'svgComponents/close';
import InfoComponent from 'commonComponents/infoComponent';
import StoryHistoryItem from 'commonComponents/storyHistoryItem';
import ActionList from 'components/sprint/execution/actionList';
import _ from 'lodash';
import AddTaskCheckBox from 'svgComponents/addTaskCheckBox';
import SelectedTaskCheckBox from 'svgComponents/selectedTaskCheckBox';
import SelectedTaskCheckBoxPopUp from 'svgComponents/selectedTaskCheckBoxPopUp';
import { checkIfPresent, addItemToArray, removeItemFromArray } from 'util/utilFunctions';
import analyticsUtil from 'util/segmentUtil';
import FeatureSwim from "svgComponents/featureSwim/featureSwim.jsx";
import FeatureSafety from "svgComponents/featureSafety/featureSafety.jsx";
import FeatureOther from "svgComponents/featureOther/featureOther.jsx";
import FeatureLounging from "svgComponents/featureLounging/featureLounging.jsx";
import FeatureRiver from "svgComponents/featureRiver/featureRiver.jsx";
import Must from 'svgComponents/must/Must.jsx';
import Could from 'svgComponents/could/Could.jsx';
import Should from 'svgComponents/should/Should.jsx';

const priorityMappings = {
	label_must: {
		image: <Must/>,
		text: 'label_must',
		backgroundColor: 'mustColor',
		textColor: '#FFFFFF'
	},
	label_should: {
		image: <Should/>,
		text: 'label_should',
		backgroundColor: 'shouldColor',
		textColor: '#FFFFFF'
	},
	label_could: {
		image: <Could/>,
		text: 'label_could',
		backgroundColor: 'couldColor',
		textColor: '#000000'
	}
};

const StoryPopup = (props) => {


	const closePopup = () => {
		props.updateUserState({
			selectedStoryId: null
		});
		props.setUiState({
			showOverlay: false,
			overlayComponentType: '',
			highlightDesktopHeader: false,
			highlightMetricsHeader: false,
			overlayComponent: ''
		});
		if(props.tutorial.isRunning){
			props.endTutorial();
			props.stopBlockerTutorial();
		}
	}

	const onClickCard = (e) => {
		e.stopPropagation();
	}

	const isCardSelected = () => {
		if (props.userState.taskArray.indexOf(props.userState.selectedStoryId) >= 0) {
			return true;
		}
		return false;
	}

	const onCardSelectClick = (storyDetails) => {
		if (isCardSelected()) {
			props.updateUserState({
				taskCount: props.userState.taskCount - 1,
				storyPointCount: props.userState.storyPointCount - storyDetails.storyPoint,
				taskArray: removeItemFromArray(props.userState.taskArray, storyDetails.id)
			});
		} else {
			props.updateUserState({
				taskCount: props.userState.taskCount + 1,
				storyPointCount: props.userState.storyPointCount + storyDetails.storyPoint,
				taskArray: addItemToArray(props.userState.taskArray, storyDetails.id)
			});
		}
	}

	const renderDetail = (name, value, infoDesc) => {
		return (
			<div styleName="each-detail-container">
				<div className={css(myStyles.detailName)} styleName="each-detail-name">{name} | </div>
				<div className={css(myStyles.detailValue)} styleName="each-detail-value">{value}</div>
				{/* <div styleName="each-detail-info">
					<InfoComponent
						tooltipText={infoDesc}
						alignTooltipKey="RIGHT"
					/>
				</div> */}
			</div>
		);
	}
	const myStyles = getSkin(props.skinGuide);
	const storyDetails = props.stories.storyList.find((eachStory) => {
		return eachStory.id === props.userState.selectedStoryId;
	});
	const userStoryDetails = props.userStories.userStoryList.find((eachUserStory) => {
		return eachUserStory.storyId === props.userState.selectedStoryId;
	});

	const { priorityTag } = storyDetails;
	const priorityInfo = priorityMappings[priorityTag] || {};

	const priorityBackgroundColor = props.skinGuide.globalProfiles.palette[priorityInfo.backgroundColor];

	const featureDetails = props.features.featuresList.find((eachFeature) => {
		return eachFeature.featureId === storyDetails.featureId;
	});

	let blockerDetails = null;
	let blockerActionsList = [];

	if (
		checkIfPresent(userStoryDetails.blockerId)
		&& userStoryDetails.blockerId !== null
	) {
		blockerDetails = props.userBlockers.blockerList.find((eachBlocker) => {
			return userStoryDetails.blockerId === eachBlocker.blockerId
		});

		const blockerActionsArray = blockerDetails.actions;
		const blockerActionsObj = _.keyBy(blockerActionsArray, 'actionId');

		// blockerActionsList = props.actions.actionsList.filter(
		// 	eachAction => blockerActionsObj[eachAction.id]
		// );

		// console.log(blockerActionsObj)
		blockerActionsList = props.actions.actionsList.map((eachAction) => {
			if (blockerActionsObj[eachAction.id]) {
				return {
					...eachAction,
					cost: blockerActionsObj[eachAction.id].cost,
					effort: blockerActionsObj[eachAction.id].effort,
					route: blockerActionsObj[eachAction.id].route,
					responseImageKey: blockerActionsObj[eachAction.id].responseImageKey
				};
			}
		})
		blockerActionsList = blockerActionsList.filter((blockerAction) => blockerAction)
		// console.log(blockerActionsList)
		// console.log(blockerActionsList,blockerActionsArray)
	}
	const renderFeatureImage = (feature) => {
		switch (feature.imageKey) {
            case 'IMG_FEATURE_RP':
                return <FeatureRiver />;
            case 'IMG_FEATURE_SB':
                return <FeatureSwim />;
            case 'IMG_FEATURE_SS':
                return <FeatureSafety />;
            case 'IMG_FEATURE_LA':
                return <FeatureLounging />;
            case 'IMG_FEATURE_OF':
                return <FeatureOther />;
            default:
                return <img alt="default" src={props.getImage('IMG_DEFAULT_FEATURE')} />;;
        }
	}

	// blockerActionsList = props.actions.actionsList;
	const featureId = storyDetails.featureId;
	const feature = props.features.featuresList.find(feature => feature.featureId === featureId);
	//const feature = props.features.featuresList[featureId-10];  //hardcoded this. might have to change later.

	let headerStyle = "header-container"
	let contentStyle = "content-container"
	let closeStyle = "close-container"
	if (checkIfPresent(blockerDetails) && userStoryDetails.progress < storyDetails.storyPoint) {
		headerStyle += " header-without-story"
		contentStyle += " content-container-blocker"
		closeStyle += " close-container-blocker"
	}

	return (
		<div
			styleName="story-popup-container"
			className={css(myStyles.storyPopupContainer)}
			onClick={onClickCard}
		>
			<div styleName="story-popup-child" id="story-popup-container"></div>
			<div styleName={headerStyle}>
				<div className={css(myStyles.headerBackground)} styleName="header-background-container"></div>
				<div styleName="header-content-container">
					<div styleName="header-details-container">
						{
							(!checkIfPresent(blockerDetails) || userStoryDetails.progress == storyDetails.storyPoint)
								?
								<div styleName="card-tag" className={css(myStyles.cardTag)}>
									<div styleName="card-tag-image">
										{renderFeatureImage(feature)}
									</div>
									<div styleName="card-tag-text" className={css(myStyles.cardTagText)}>
										{props.getLabel(feature.name)}
									</div>
								</div>

								: null
						}
						<div className={css(myStyles.storyName)} styleName="story-name">
							{props.getLabel(storyDetails.name)}
						</div>
						<div styleName="story-details" className={css(myStyles.storyDetails)}>
							{
								(!checkIfPresent(blockerDetails) || userStoryDetails.progress == storyDetails.storyPoint)
									? renderDetail(
										props.getLabel('label_story_point'),
										storyDetails.storyPoint,
										props.getLabel(storyDetails.storyPointDescription)
									)
									: null
							}
							{/* {
								checkIfPresent(userStoryDetails.storyPriority)
									? renderDetail(
										props.getLabel('label_task_priority'),
										userStoryDetails.storyPriority,
										props.getLabel('label_task_priority_help_text')
									)
									: null
							} */}
							{
								(!checkIfPresent(blockerDetails) || userStoryDetails.progress == storyDetails.storyPoint)
									? <div
										styleName="priority-container" className={css(myStyles.priorityContainer)}
										style={{
											backgroundColor: priorityBackgroundColor,
											color: priorityInfo.textColor
										}}
									>
										<div styleName="priority-image-container">
											{priorityInfo.image}
										</div>
										<div styleName="priority-text" className={css(myStyles.priorityText)}>
											{props.getLabel(priorityInfo.text)}
										</div>
									</div>
									: null
							}
						</div>
					</div>
					<div styleName={closeStyle}>
						<div styleName="close-icon" onClick={closePopup}>
							<Close />
						</div>
					</div>
				</div>
			</div>
			<div styleName={contentStyle}>
				<div styleName={props.userState.currentSprintState !== 1 ? "story-description-container" : "story-description-container-full"}>
					<div className={checkIfPresent(storyDetails.storyPoint) ? css(myStyles.descHeading) : css(myStyles.descBlocker)} styleName="story-description-heading">
						{props.getLabel('label_description')}
					</div>
					<div className={css(myStyles.descContent)} styleName="story-description-content">
						{props.getLabel(storyDetails.description)}
					</div>
					{/* <div className={css(myStyles.featureDescHeading)} styleName="feature-description-heading">
						{renderFeatureImage(featureDetails)}
						{props.getLabel(featureDetails.name + "_caps").includes("label") ? props.getLabel(featureDetails.name) : props.getLabel(featureDetails.name  + "_caps")}
					</div>
					<div className={css(myStyles.featureDescContent)} styleName="feature-description-content">
						{props.getLabel(featureDetails.description)}
					</div> */}
					{
						props.userState.currentSprintState === 1 && userStoryDetails.progress < storyDetails.storyPoint
							? <div
								styleName="selection-container"
							>
								<div
									className={isCardSelected() ? css(myStyles.selectionContainer, myStyles.selectionBoxShadow) : css(myStyles.selectionContainer, myStyles.selectContainer)}
									styleName="selection-content"
									onClick={() => { onCardSelectClick(storyDetails) }}
								>
									<div styleName="checkbox-container">
										{
											isCardSelected()
												? <SelectedTaskCheckBoxPopUp />
												: <AddTaskCheckBox />
										}
									</div>
									<div className={css(myStyles.selectionText)} styleName="text-container">
										{
											isCardSelected()
												? props.getLabel('label_story_is_selected')
												: props.getLabel('label_select_this_story')
										}
										{ }
									</div>
								</div>
							</div>
							: null
					}
				</div>
				{
					(checkIfPresent(blockerDetails) && userStoryDetails.progress < storyDetails.storyPoint)
						? <div className={css(myStyles.storyHistoryContainer)}
							styleName={props.userState.currentSprintState !== 1 ? "story-history-container" : "story-history-container-full"}
						>
							{/* <div className={css(myStyles.descHeading)} styleName="story-history-heading">
								{props.getLabel('label_history')}
							</div> */}
							<div styleName="story-history-content">
								<StoryHistoryItem
									sprintNumber={blockerDetails.sprintNumber}
									sprintDay={blockerDetails.sprintDay}
									type="BLOCKER"
									name={props.getLabel(blockerDetails.name)}
									desc={props.getLabel(blockerDetails.description)}
									priorityBackgroundColor={priorityBackgroundColor}
									priorityInfo={priorityInfo}
									storyPoint={storyDetails.storyPoint}
								/>
							</div>
						</div>
						: null
				}
				{
					(checkIfPresent(blockerDetails) && props.userState.currentSprintState !== 1 && userStoryDetails.progress < storyDetails.storyPoint)
						? <div styleName="story-actions-container">
							<ActionList
								{...props}
								actionsList={blockerActionsList}
								isActionsFromStoryPopup={true}
								isActionsFromEventPopup={false}
							/>
						</div>
						: null
				}
				{/* <div styleName="story-select" className={css(myStyles.storySelect)}>
					<div styleName="story-select-icon">
						<img src={props.getImage('IMG_SQ_TIC')}></img>
					</div> 
					<div styleName="story-select-text" className={css(myStyles.storySelectText)}>
						Story is selected
					</div>
				</div> */}
			</div>
		</div>
	);
}

export default applyWrappers(StoryPopup, styles);