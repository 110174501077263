import React from 'react';
import styles from './historySvg.module.sass';
// import getSkin from './skin.js';
// import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import {checkIfPresent} from 'util/utilFunctions';

const HistorySvg = (props) => {

	// const myStyles = getSkin(props.skinGuide);
	const skin = props.skinGuide.globalProfiles.palette
    let color = skin.primaryColor;
    if (checkIfPresent(props.svgColor)) {
        color = props.svgColor;
    }
	return (
		<div styleName="main-component">
			<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.26 3.00007C8.17 2.86007 4 6.95007 4 12.0001H2.21C1.76 12.0001 1.54 12.5401 1.86 12.8501L4.65 15.6501C4.85 15.8501 5.16 15.8501 5.36 15.6501L8.15 12.8501C8.2191 12.7796 8.26579 12.6902 8.28418 12.5932C8.30257 12.4962 8.29184 12.396 8.25335 12.3051C8.21485 12.2142 8.1503 12.1367 8.06785 12.0824C7.98539 12.0281 7.88871 11.9995 7.79 12.0001H6C6 8.10007 9.18 4.95007 13.1 5.00007C16.82 5.05007 19.95 8.18007 20 11.9001C20.05 15.8101 16.9 19.0001 13 19.0001C11.39 19.0001 9.9 18.4501 8.72 17.5201C8.52846 17.3692 8.28815 17.294 8.04477 17.3087C7.80138 17.3235 7.57191 17.4272 7.4 17.6001C6.98 18.0201 7.01 18.7301 7.48 19.0901C9.05113 20.3325 10.997 21.0058 13 21.0001C18.05 21.0001 22.14 16.8301 22 11.7401C21.87 7.05007 17.95 3.13007 13.26 3.00007ZM12.75 8.00007C12.34 8.00007 12 8.34007 12 8.75007V12.4301C12 12.7801 12.19 13.1101 12.49 13.2901L15.61 15.1401C15.97 15.3501 16.43 15.2301 16.64 14.8801C16.85 14.5201 16.73 14.0601 16.38 13.8501L13.5 12.1401V8.74007C13.5 8.34007 13.16 8.00007 12.75 8.00007Z" fill="white"/>
			</svg>
		</div>
	);
}

export default applyWrappers(HistorySvg, styles);