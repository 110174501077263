import React from 'react';
import { connect } from 'react-redux';
import ActionResponse from 'components/sprint/execution/actionResponse';
import { setUiState } from 'actions/uiState/actionCreators';
import { setWalkthroughState } from 'actions/walkthrough/actionCreators';
import { endSprint } from 'actions/apiAction/endSprint';
import {startTutorial,endTutorial,stopBlockerTutorial} from 'actions/tutorial';
import tutorial from '../reducers/tutorial';

class ActionResponseContainer extends React.Component {

    render() {
        return (
            <ActionResponse {...this.props} />
        );
    }
}

ActionResponseContainer.propTypes = {};

ActionResponseContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        userActions: state.userActions,
        userEvents: state.userEvents,
        actions: state.actions,
        metrics: state.metrics,
        userState: state.userState,
        prds: state.prds,
        userPrds: state.userPrds,
        userMetrics: state.userMetrics,
        uiState: state.uiState,
        tutorial: state.tutorial
    };
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    setWalkthroughState: (walkthroughState) => {
        dispatch(setWalkthroughState(walkthroughState));
    },
    endSprint: (payload, callback) => {
        dispatch(endSprint(payload, callback));
    },
    startTutorial: () => {
		dispatch(startTutorial())
	},
    endTutorial: () => {
        dispatch(endTutorial());
    },
    stopBlockerTutorial: () => {
        dispatch(stopBlockerTutorial());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionResponseContainer);
