import React from "react";
import styles from "./completion.module.sass";
import getSkin from "./skin";
import applyWrappers from "wrappers/ComponentWrapper";
import CircularProgressBar from "commonComponents/circularProgressBar";
import { css } from "aphrodite/no-important";

const Completion = props => {
	const myStyles = getSkin(props.skinGuide);

	const { sprinStories, getLabel } = props;
	const total = sprinStories.length;
	const completed = sprinStories.filter(story => {
		return story.storyStatus === 4;
	}).length;
	let percentage = 50;
	if (total != 0) {
		percentage = (completed / total) * 100;
	}
	let fillColor = "#B5F000";
	if (percentage == 0) fillColor = "#ECECEC";
	return (
		<div
			styleName="wrap"
			className={
				props.fromPopup ? css(myStyles.wrapPopup) : css(myStyles.wrap)
			}
		>
			<div styleName="legend">
				<div className={css(myStyles.legendLabelBacklog)}>
					{props.getLabel("label_backlog_items")}
				</div>
				<div styleName="leg-wrap">
					<div styleName="square green"></div>
					<div
						className={
							props.fromPopup
								? css(myStyles.legendLabelPopup)
								: css(myStyles.legendLabel)
						}
					>
						{getLabel("label_report_tasks_done")}
					</div>
				</div>
				<div styleName="leg-wrap">
					<div
						styleName={
							props.fromPopup ? "square grey" : "square white"
						}
					></div>
					<div
						className={
							props.fromPopup
								? css(myStyles.legendLabelPopup)
								: css(myStyles.legendLabel)
						}
					>
						{getLabel("label_report_tasks_unfinished")}
					</div>
				</div>
			</div>
			<div styleName="graph">
				<div
					styleName="semi-donut-container"
					style={{
						"--percentage": `${percentage}`,
						"--fill": `${fillColor}`
					}}
				>
					<div
						className={css(myStyles.semiDonutValue)}
						style={{ position: "relative", zIndex: 1 }}
					>
						<span style={{ color: "#000000" }}>{completed}/</span>
						{total}
					</div>
					<div styleName="semi-donut">
						<div styleName="line-shadow"></div>
						<div styleName="semi-circle"></div>
					</div>
					<div styleName="semi-donut-outer-shadow"></div>
					<div styleName="semi-donut-inner-shadow"></div>
					<div styleName="semi-donut-left-shadow"></div>
					<div styleName="semi-donut-right-shadow"></div>
				</div>
				<div className={css(myStyles.backlogItemsDone)}>
					{props.getLabel("label_backlog_items_done")}
				</div>
			</div>
			{/* <div styleName="legend">
				<div styleName="leg-wrap">
					<div styleName="circle blue"></div>
					<div>{completed} {getLabel("label_report_tasks_done")}</div>
				</div>
				<div styleName="leg-wrap">
					<div styleName="circle pink"></div>
					<div>{total - completed} {getLabel("label_report_tasks_unfinished")}</div>
				</div>
			</div> */}
		</div>
	);
};

export default applyWrappers(Completion, styles);
