import React, { useState } from 'react';
import styles from './sprintReport.module.sass';
import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';
import { css } from 'aphrodite/no-important';
import GameComplete from 'svgComponents/gameComplete';
import SprintFooter from '../sprintFooter/SprintFooter.jsx';
import WalkthroughTabs from 'commonComponents/walkthrough/walkthroughTabs';
import { walkthroughTabsList } from 'config/walkthrough';
import {
	showFeedbackFormWithoutPopup,
} from 'util/feedback';
import FilledButton from 'commonComponents/buttons/filledButton';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import History from 'components/metricsHeader/history';
import SendEmailReport from 'commonComponents/sendEmailReport';
import analyticsUtil from 'util/segmentUtil';
import GameIncomplete from '../../../../svgComponents/gameIncomplete/GameIncomplete.jsx';
import SprintReportPopup from './SprintReportPopup.jsx';

const downloadReport = (props) => {
	//Segment integration
	analyticsUtil.track('Report_Download', props.userDetails);
	props.setIsDownloading(true);
	props.fetchReportLink();
}

const SprintReport = (props) => {

	const myStyles = getSkin(props.skinGuide);
	const {
		userState,
		metrics,
		userSprintReports,
		getLabel,
		stories,
		report,
		fromPopup,
		customers
	} = props;
	const { isGameCompleted } = userState;

	// console.log(isGameCompleted)

	// const [selected, setSelected] = useState(!fromPopup ? props.userState.currentSprintNumber : 1);

	const renderWalkthroughTabs = () => {
		if (isGameCompleted) {
			return (
				<div styleName="walkthrough-tabs-container">
					<WalkthroughTabs walkthroughTabsList={updatedWalkthroughTabsList}/>
				</div>
			);
		}
		return null;
	}

	const disableFooterCondition = () => {
        return (
            props.userState.isGameCompleted === true
            || props.userState.daysCompleted === true
            || props.userState.timeCompleted === true
			|| fromPopup === true
        );
    }

	const setFeedbackCompleted = () => {
		props.setUserState({
			isFeedbackSubmitInProgress: false,
			isKfeedbackSubmitted: true
		});
	}

	const onClickFeedback = () => {
		props.setUserState({
			isFeedbackSubmitInProgress: true
		});
		showFeedbackFormWithoutPopup(setFeedbackCompleted);
	}


	const mvpUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		props.userMetrics.userMetricsList,
		'MVP'
	);


	const updatedWalkthroughTabsList = walkthroughTabsList.map(walkthroughTab => {
		// if (walkthroughTab.key === 'launch') {
		// 	return {
		// 		...walkthroughTab,
		// 		isSelected: true,
		// 		isCompleted: false
		// 	};
		// }
		return {
			...walkthroughTab,
			isSelected: false,
			isCompleted: true
		};
	});


	// if (userSprintReports.sprintReportList.length === 0) {
	// 	return (
	// 		<div styleName="master" 
	// 		// style={
	// 		// 	{
	// 		// 		'height': `calc(100% - ${isGameCompleted || fromPopup ? 0 : 80}px)`
	// 		// 	}
	// 		// }
	// 		>
	// 			{ !fromPopup ?
	// 			<div styleName="walkthrough-background">
	// 				<img width="100%" height="100%" src={props.getImage('SIM_BACKGROUND')} alt="" 
	// 				style={isGameCompleted ? null : { filter: "blur(4px)" }}/>
	// 			</div>:
	// 			null
	// 			}
	// 			<div styleName="sprint-report-container">
	// 				<div styleName={fromPopup ? "sprint-report-popup-content-container" : "sprint-report-content-container"}>
	// 					{renderWalkthroughTabs()}
	// 					{
	// 						isGameCompleted
	// 							? <div styleName="sprint-report-component game-completed">
	// 								<div styleName="img">
	// 									{
	// 										mvpUserMetrics.value == 100
	// 											? <GameComplete/>
	// 											: <GameIncomplete/>
	// 									}
	// 								</div>
	// 								<div styleName="content">
	// 									<div styleName="title" className={css(myStyles.title)}>
	// 										{
	// 											mvpUserMetrics.value === 100
	// 												? getLabel("label_report_congrats_mvp_achieved")
	// 												: getLabel("label_report_congrats_days_over")
	// 										}
	// 									</div>
	// 									<div styleName="ms" className={css(myStyles.ms)}>
	// 										{
	// 											mvpUserMetrics.value === 100
	// 												? getLabel("label_report_productcustfit_achieved")
	// 												: getLabel("label_report_days_over")
	// 										}
	// 									</div>

	// 									{!props.userState.isVacEnabled ?
	// 										<div styleName="buttons-container">
	// 											{/* {
	// 												props.user.isKfeedbackInitialized === true && props.user.isKfeedbackSubmitted === false
	// 													? <div styleName="single-button-container">
	// 														<FilledButton
	// 															textLabel={props.user.isFeedbackSubmitInProgress ? props.getLabel('label_give_feedback_loading') : props.getLabel('label_give_feedback')}
	// 															clickFunction={props.user.isFeedbackSubmitInProgress ? null : onClickFeedback}
	// 															disableButton={props.user.isFeedbackSubmitInProgress}
	// 															removeShadow={true}
	// 														// buttonStyle={{

	// 														// }}
	// 														// buttonTextStyle={{

	// 														// }}
	// 														/>
	// 													</div>
	// 													: null
	// 											} */}
	// 											<div styleName="single-button-container">
	// 												<FilledButton
	// 													textLabel={report.isReportDownloading ? getLabel("label_report_downloading") : getLabel("label_report_download")}
	// 													clickFunction={report.isReportDownloading ? null : () => { downloadReport(props) }}
	// 													disableButton={props.user.isKfeedbackSubmitted === false || report.isReportDownloading}
	// 													removeShadow={true}
	// 												/>
	// 												{/* {
	// 													props.user.isKfeedbackSubmitted === true
	// 														? null
	// 														: <div className={css(myStyles.helpTextReport)} styleName="help-text-report">{props.getLabel('label_provide_feedback_for_report')}</div>
	// 												} */}
	// 											</div>
	// 											{
	// 												props.user.isKfeedbackSubmitted === true
	// 													? <SendEmailReport {...props} />
	// 													: null
	// 											}
	// 										</div>
	// 										: null}

	// 								</div>
	// 								<div styleName="footer-svg1">
	// 									<svg  viewBox="0 0 1206 74" fill="none" xmlns="http://www.w3.org/2000/svg">
	// 										<path opacity="0.1" d="M0 74.1999V0C0 0 326.31 76.3913 735.462 28C1144.61 -20.3913 1376.46 74.1999 1376.46 74.1999H0Z" fill="#972082" />
	// 									</svg>
	// 								</div>
	// 								<div styleName="footer-svg2">
	// 									<svg viewBox="0 0 766 74" fill="none" xmlns="http://www.w3.org/2000/svg">
	// 										<path opacity="0.1" d="M0.462891 73.6622V0C0.462891 0 128.463 87.5 326.963 22C525.463 -43.5 765.582 73.6622 765.582 73.6622H0.462891Z" fill="#972082" />
	// 									</svg>
	// 								</div>
	// 								<div styleName="footer-svg3">
	// 									<svg width="17.716vw" height="9.635vh"  viewBox="0 0 242 74" fill="none" xmlns="http://www.w3.org/2000/svg">
	// 										<path opacity="0.1" d="M765.582 73.6622V0C765.582 0 637.582 87.5 439.082 22C240.582 -43.5 0.462524 73.6622 0.462524 73.6622H765.582Z" fill="#972082" />
	// 									</svg>
	// 								</div>
	// 							</div>
	// 							: null
	// 					}
	// 				</div>
	// 				{/* {disableFooterCondition() ? null : <SprintFooter {...props} />} */}
	// 			</div>
	// 		</div>
	// 	);
	// }
	
	return (
		<div styleName="master" 
		// style={
		// 	{
		// 		'height': `calc(100% - ${isGameCompleted || fromPopup ? 0 : 80}px)`
		// 	}
		// }
		>
			{ !fromPopup ?
			<div styleName="walkthrough-background">
				<img width="100%" height="100%" src={props.getImage('SIM_BACKGROUND')} alt="" 
				style={isGameCompleted ? null : { filter: "blur(4px)" }}/>
			</div>:
			null
			}
			<div styleName="sprint-report-container"
			style={fromPopup ? {background:"white"}:null}>
				<div styleName={fromPopup ? "sprint-report-popup-content-container" : "sprint-report-content-container"}>
					{renderWalkthroughTabs()}
					{
						isGameCompleted
							? <div styleName="sprint-report-component game-completed">
								<div styleName="img">
									{
										mvpUserMetrics.value == 100
											? <GameComplete/>
											: <GameIncomplete/>
									}
								</div>
								<div styleName="content">
									<div styleName="title" className={css(myStyles.title)}>
										{
											mvpUserMetrics.value == 100
												? getLabel("label_report_congrats_mvp_achieved")
												: getLabel("label_report_congrats_days_over")
										}
									</div>
									<div styleName="ms" className={css(myStyles.ms)}>
										{
											mvpUserMetrics.value == 100
												? getLabel("label_report_productcustfit_achieved")
												: getLabel("label_report_days_over")
										}
									</div>

									<div styleName="buttons-container">
										{/* {
											props.userState.isFeedbackEnabled == true && props.user.isKfeedbackInitialized == true && props.user.isKfeedbackSubmitted == false
												? <div styleName="single-button-container">
													<FilledButton
														textLabel={props.user.isFeedbackSubmitInProgress ? props.getLabel('label_give_feedback_loading') : props.getLabel('label_give_feedback')}
														clickFunction={props.user.isFeedbackSubmitInProgress ? null : onClickFeedback}
														disableButton={props.user.isFeedbackSubmitInProgress}
														removeShadow={true}
														buttonStyle={{
															background: 'transparent',
															borderColor: 'white'
														}}
														buttonTextStyle={{
															fontWeight: 600,
															color: 'white'
														}}
													/>
												</div>
												: null
										} */}
										{
											props.userState.isFinalReportEnabled
												? <div styleName="single-button-container">
													<FilledButton
														textLabel={report.isReportDownloading ? getLabel("label_report_downloading") : getLabel("label_report_download")}
														clickFunction={report.isReportDownloading ? null : () => { downloadReport(props) }}
														disableButton={(props.userState.isFeedbackEnabled == true && props.user.isKfeedbackSubmitted == false) || report.isReportDownloading}
														removeShadow={true}
													/>
													{/* {
														props.user.isKfeedbackSubmitted == true || props.userState.isFeedbackEnabled == false
															? null
															: <div className={css(myStyles.helpTextReport)} styleName="help-text-report">{props.getLabel('label_provide_feedback_for_report')}</div>
													} */}
												</div>
												: null
										}

										{
											props.userState.isFinalReportEnabled && (props.user.isKfeedbackSubmitted === true || props.userState.isFeedbackEnabled == false)
												? <SendEmailReport {...props} />
												: null
										}
									</div>
								</div>
								<div styleName="footer-svg1">
									<svg viewBox="0 0 1206 74" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.1" d="M0 74.1999V0C0 0 326.31 76.3913 735.462 28C1144.61 -20.3913 1376.46 74.1999 1376.46 74.1999H0Z" fill="#972082" />
									</svg>
								</div>
								<div styleName="footer-svg2">
									<svg viewBox="0 0 766 74" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.1" d="M0.462891 73.6622V0C0.462891 0 128.463 87.5 326.963 22C525.463 -43.5 765.582 73.6622 765.582 73.6622H0.462891Z" fill="#972082" />
									</svg>
								</div>
								<div styleName="footer-svg3">
									<svg width="17.716vw" height="9.635vh" viewBox="0 0 242 74" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.1" d="M765.582 73.6622V0C765.582 0 637.582 87.5 439.082 22C240.582 -43.5 0.462524 73.6622 0.462524 73.6622H765.582Z" fill="#972082" />
									</svg>
								</div>
							</div>
							: null
					}
					{userSprintReports.sprintReportList.length > 0?
					<div style={{paddingBottom: "1vh"}}>
						{/* {isGameCompleted ?
							<div styleName="walkthrough-background">
								<img width="100%" height="100%" src={props.getImage('SIM_BACKGROUND_BLUR')} alt="" />
							</div>
							: null} */}
						{!fromPopup && !isGameCompleted ? 
						<div styleName="tabs" className={css(myStyles.tabs)}>
							<History {...props} height="40px" />
						</div>
						:
						null}
						<SprintReportPopup {...props}/>
					</div>
					: null}
				</div>
				{/* {disableFooterCondition() ? null : <SprintFooter {...props} />} */}
			</div>
		</div>
	);
}

export default applyWrappers(SprintReport, styles);