import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins';

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
        big : {

        },
        mvpText: {
			...myTypography.body2,
            fontFamily: skins.fontFamily.timerFamily,
			lineHeight: 1,
			color: skin.black,
			fontWeight: 400,
			fontSize: '1em'
		},
		mvpValue: {
			...myTypography.body1,
			fontFamily: skins.fontFamily.titleFamily,
			lineHeight: 1,
			fontSize: '1.5em',
			fontWeight: 600,
			color: skin.black,
		},
		mvpProgressBarContainer: {
			background: 'rgba(0,0,0,0.3)'
		},
		mvpProgressBar: {
			background: '#0B7F3D'
		},
		name: {
			color: skin.black,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 400,
			fontSize: '2.083vh'
		},
		completion: {
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: '1.5em'
		},
		bChangePos :{
			background: '#E7FBEA',
			color: '#0B7F3D',
			fontWeight: 700,
			fontSize: '0.75em',
			fontFamily: skins.fontFamily.bodyFamily
		},
		bChangeNeg :{
			background: '#FFDEE6',
			color: '#D11021',
			fontWeight: 700,
			fontSize: '0.75em',
			fontFamily: skins.fontFamily.bodyFamily
		},
		metName :{
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			fontSize: '1.25em',
			background : 'rgba(0,0,0,0.6)'
		},
		metNamePopup : {
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			fontSize: '1.25em',
			background : 'rgba(0, 0, 0, 0.7)'
		},
		reName: {
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 400,
			fontSize: '2.083vh',

		},
		reCompletion: {
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: '1.5em'
		}
    });
}

export default getSkin;