import React from 'react';
import styles from './actionResponseMetrics.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import IncrementArrow from 'svgComponents/incrementArrow';
import DecrementArrow from 'svgComponents/decrementArrow';
import FlagIcon from 'svgComponents/flagIcon';
import SprintMetrics from './sprintMetrics';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import Budget from 'svgComponents/budget';
import Skill from 'svgComponents/skill';
import Morale from 'svgComponents/morale';
import CustSat from 'svgComponents/custSat';

const ActionResponseMetrics = (props) => {
	const getGlobalMetricsValue = (key, defaultValue = 0, defaultDiff = 0) => {
		const individualUserMetrics = getUserMetricsForMetricsKey(
			props.metrics.metricsList,
			props.userMetrics.userMetricsList,
			key
		);

		let value = defaultValue;
		let diff = defaultDiff;
		if (individualUserMetrics) {
			value = individualUserMetrics.value;
			diff = individualUserMetrics.diff;
		}

		return { value, diff };
	}

	const getMetricsvalue = (key, defaultValue = 0, defaultDiff = 0) => {
		const individualUserMetrics = getUserMetricsForMetricsKey(
			props.metrics.metricsList,
			props.latestUserActionResponse.userMetrics,
			key
		);

		let value = defaultValue;
		let diff = defaultDiff;

		if (individualUserMetrics) {
			value = individualUserMetrics.value;
			diff = individualUserMetrics.diff;
		}

		return { value, diff };
	}

	const wofwDefaultValue = getGlobalMetricsValue('WAYOFWORKING').value;
	const wofwMetricsValue = getMetricsvalue('WAYOFWORKING', wofwDefaultValue);
	const wofw = wofwMetricsValue.value;
	const wofwDiff = wofwMetricsValue.diff;

	const bfDefaultValue = getGlobalMetricsValue('BUSINESSFOCUS').value;
	const bfMetricsValue = getMetricsvalue('BUSINESSFOCUS', bfDefaultValue);
	const bf = bfMetricsValue.value;
	const bfDiff = bfMetricsValue.diff;

	const progressDefaultValue = getGlobalMetricsValue('PROGRESS').value;
	const progressMetricsValue = getMetricsvalue('PROGRESS', progressDefaultValue);
	const progress = progressMetricsValue.value;
	const progressDiff = progressMetricsValue.diff;

	const mvpMetricsValue = getGlobalMetricsValue('MVP');
	const mvpValue = mvpMetricsValue.value;
	const budgetDefaultValue = getGlobalMetricsValue('BUDGET').value;
	const budgetDefaultDiff = getGlobalMetricsValue('BUDGET').diff;
	const budgetMetricsValue = getMetricsvalue('BUDGET', budgetDefaultValue, budgetDefaultDiff);
	const budget = budgetMetricsValue.value;
	const budgetDiff = budgetMetricsValue.diff;

	const totalDays = props.userState.totalDays;
	const daysRemaining = totalDays - props.userState.currentDay;
	const previousDay = props.latestUserActionResponse.sprintDay || 1;
	const daysRemainingDiff = props.userState.currentSprintDay - previousDay;

	const myStyles = getSkin(props.skinGuide);

	const getTagBGClass = (changeType) => {
		switch (changeType) {
			case 'positive':
				return 'greenTag';

			case 'negative':
				return 'redTag';

			default:
				return 'greenTag';
		}
	}

	const renderArrow = (changeType) => {
		switch (changeType) {
			case 'positive':
				return (
					<div styleName="arrow-container">
						<IncrementArrow />
					</div>
				);

			case 'negative':
				return (
					<div styleName="arrow-container">
						<DecrementArrow />
					</div>
				);

			default:
				return null;
		}
	}

	const getMetricImage = (metricType,color) => {
		switch (props.getLabel(metricType)) {
			case 'Budget Remaining':
				return <Budget color={color}/>;
			case 'Way of Working':
				return <Skill color={color}/>;
			case 'Business Focus':
				return <Morale color={color}/>;
			case 'Throughput':
				return <CustSat color={color}/>;
			default:
				return;
		}
	}

	const renderMVP = (title, value) => {
		return (
			<div className={css(myStyles.leftMetricContainer)} styleName="left-metric-container">
				<div styleName="mvp-title-value-container">
					<div className={css(myStyles.metricTitle)} styleName="metric-title">{props.getLabel(title)}</div>
					<div className={css(myStyles.metricValue)} styleName="mvp-value">{value}</div>
				</div>
				<div styleName="left-metrics-value-change-container">
					<div styleName="sim-mvp-progress-bar-container" className={css(myStyles.mvpProgressBarContainer)}>
						<div
							style={{
								width: `${value}`
							}}
							styleName="sim-mvp-progress-bar"
							className={css(myStyles.mvpProgressBar)}
						></div>
					</div>
				</div>
			</div>
		);
	}

	const renderTag = (changeValue, changeType, metricType) => {
		if (changeType == 'neutral') {
			return null;
		}
		const tagBGClass = getTagBGClass(changeType);
		let changePercent = changeValue;
		const title = props.getLabel(metricType);
		if(title=== 'Throughput' || title === 'Way of Working' || title === 'Business Focus'){
			changePercent += '%'
		}

		return (
			<div
				className={css(myStyles.metricChange, myStyles[tagBGClass])}
				styleName="metrics-change"
			>
				{renderArrow(changeType)}
				<span>{changePercent}</span>
			</div>
		);
	}

	const sprintTotalDays = props.userState.totalDaysInSprint;
	const totalSprints = props.userState.totalDays/sprintTotalDays;
	const sprintSections = 3;

	const getSprintSection = (day, totalDays, sections) => {   // will return value from 1 to 15
		const week = (totalDays/(sections*totalSprints));
		return 	Math.ceil(day/week);
	};

	const currentDaySection = getSprintSection((props.userState.currentSprintNumber-1)*sprintTotalDays+props.userState.currentSprintDay, totalDays, sprintSections)
	const weeksToDeadline = totalSprints*sprintSections - currentDaySection + 1;

	const renderRightMetrics = (title, value, changeValue, changeType) => {
		return (
			<div styleName="metric-container" className={css(myStyles.metricContainer)}>
				<div styleName="metric-content" className={css(myStyles.metricContent)}>
					{/* <div styleName="metric-name-value-container"> */}
						<div styleName="metric-value" className={css(myStyles.metricValueRight)}>
							{`${props.getLabel(value)}/${totalSprints*sprintSections}`}
						</div>
						<div styleName="metric-name-desc-container">
							<div styleName="metric-value-right" className={css(myStyles.metricNameRight)}>
								{props.getLabel(title)}
							</div>
						</div>
					{/* </div> */}
					{/* {renderTag(changeValue, changeType,title)} */}
				</div>
			</div>
		);
	}

	const renderMVPMetrics = (title, value, changeValue, changeType) => {
		return (
			<div styleName="metric-container" className={css(myStyles.metricContainer)}>
				<div styleName="metric-content" className={css(myStyles.metricContent)}>
					{/* <div styleName="metric-name-value-container"> */}
						<div styleName="metric-value-right" className={css(myStyles.metricValueRight)}>
								{props.getLabel(value)}
						</div>
						<div styleName="metric-name-desc-container">
							<div styleName="metric-name" className={css(myStyles.metricNameRight)}>
								{props.getLabel(title)}
							</div>
						</div>
					{/* </div> */}
					{/* {renderTag(changeValue, changeType,title)} */}
				</div>
			</div>
		);
	}

	const renderGameMetrics = (title, value, changeValue, changeType, rightBorder = false) => {
		return (
			// <div className={rightBorder ? css(myStyles.gameMetricContainerRightBorder) : css(myStyles.gameMetricContainer)} styleName="game-metric-container">
			// 	<div className={css(myStyles.metricTitle)} styleName="metric-title">{props.getLabel(title)}</div>
			// 	<div styleName="left-metrics-value-change-container">
			// 		<div styleName="metrics-image">
			// 			<FlagIcon />
			// 		</div>
			// 		<div className={css(myStyles.metricValue)} styleName="metrics-value">{value}</div>
			// 		{renderTag(changeValue, changeType)}
			// 	</div>
			// </div>
			<div styleName="metric-container" className={css(myStyles.metricContainer)}>
				<div styleName="metric-content" className={css(myStyles.metricContent)}>
					<div styleName="metric-image-container">
						<div styleName="metric-image">
							{getMetricImage(title,"black")}
						</div>
					</div>
					<div styleName="metric-name-value-container">
						<div styleName="metric-name-desc-container">
							<div styleName="metric-name" className={css(myStyles.metricName)}>
								{props.getLabel(title)}
							</div>
							
						</div>
						<div styleName="metric-value" className={css(myStyles.metricValue)}>
							{props.getLabel(value)}
						</div>
					</div>
					{renderTag(changeValue, changeType, title)}
				</div>
			</div>
		);
	}

	const renderBottomMetrics = () => {
		return ([
			renderMVPMetrics(
				'label_mvp_alignment',
				`${mvpValue}%`,
			),
			renderRightMetrics(
				'label_days_remaining',
				`${weeksToDeadline}`,
				`${props.getLabel('label_number_of_days',"",{DAY_NUMBER:daysRemainingDiff})}`,
				'negative'
			),
			
		]);
	}

	const renderTopMetrics = () => {
		return ([
			<SprintMetrics
				latestActionOption={props.latestActionOption}
				latestUserActionResponse={props.latestUserActionResponse}
				userState={props.userState}
				prds={props.prds}
				userPrds={props.userPrds}
			/>,
			<div className={css(myStyles.gameMetricsContainer)} styleName="game-metrics-container">
				{renderGameMetrics(
					'label_cust',
					`${progress}%`,
					`${progressDiff >= 0 ? progressDiff : -progressDiff}`,
					progressDiff > 0 ? 'positive' : progressDiff < 0 ? 'negative' : 'neutral',
					false
				)}
				{renderGameMetrics(
					'label_skill',
					`${wofw}%`,
					`${wofwDiff >= 0 ? wofwDiff : -wofwDiff}`,
					wofwDiff > 0 ? 'positive' : wofwDiff < 0 ? 'negative' : 'neutral',
					true
				)}
				{renderGameMetrics(
					'label_morale',
					`${bf}%`,
					`${bfDiff >= 0 ? bfDiff : -bfDiff}`,
					bfDiff > 0 ? 'positive' : bfDiff < 0 ? 'negative' : 'neutral',
					true
				)}
				{renderGameMetrics(
					'label_budget_remaining',
					`₣${budget}`,
					`₣${budgetDiff >= 0 ? budgetDiff : -budgetDiff}`,
					budgetDiff > 0 ? 'positive' : budgetDiff < 0 ? 'negative' : 'neutral'
				)}
			</div>
		]);
	}

	return (
		<div styleName="metrics-container">
			<div className={css(myStyles.leftMetricsContainer)} styleName="top-metrics-container">
				{renderTopMetrics()}
			</div>
			<div styleName="bottom-metrics-container">
				<div className={css(myStyles.bottomMetricsWrapper)} styleName="bottom-metrics-wrapper">
					{renderBottomMetrics()}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(ActionResponseMetrics, styles);