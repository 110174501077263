import { StyleSheet } from "aphrodite";
import { hexToRgbA } from "util/styleUtil";
import typography from "defaults/typography";
import skins from "defaults/skins";

const getSkin = theme => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		header: {
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: "1.5em",
			color: skin.white,
			borderTopLeftRadius: "8px",
			borderTopRightRadius: "8px"
		},
		headerInteractive: {
			background: "linear-gradient(90deg, #972082 0%, #4B1C40 100%)"
		},
		headerPositive: {
			background: "#0B7F3D"
		},
		headerNegative: {
			background: "#D11021"
		},
		headerNeutral: {
			background: "#FBBD0E"
		},
		eventTitle: {
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 600,
			fontSize: "1.5em"
		},
		eventDescription: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 500,
			fontSize: "1em"
		},
		eventActionsContent: {
			background: skin.white,
			borderBottomLeftRadius: "8px",
			borderBottomRightRadius: "8px"
		},
		metricTitle: {
			...myTypography.body1,
			fontWeight: 600,
			fontSize: "0.75em",
			lineHeight: "normal",
			color: hexToRgbA(skin.primaryColor, 0.8)
		},
		metricChange: {
			...myTypography.body1,
			fontWeight: 700,
			fontSize: "0.75em",
			lineHeight: "normal"
		},
		greenTag: {
			background: `#E7FBEA`,
			color: "#0B7F3D"
		},
		redTag: {
			background: `#FFDEE6`,
			color: "#D11021"
		},
		metricContainer: {
			backgroundColor: skin.white,
			border: `1px solid ${skin.black}`,
			borderRadius: "4px"
			// boxShadow: `0 1px 1px 2px ${hexToRgbA(skin.white, 0.1)}`
		},
		metricName: {
			...myTypography.body2,
			fontFamily: skins.fontFamily.titleFamily,
			fontWeight: 500,
			fontSize: "0.625em",
			lineHeight: 1,
			color: skin.black
		},
		metricValue: {
			...myTypography.body1,
			fontSize: "1em",
			fontWeight: 600,
			color: skin.black
		},
		actionChoice: {
			fontFamily: skins.fontFamily.titleFamily,
			fontSize: "0.75em",
			fontWeight: "600",
			color: "#031A20"
		}
	});
};

export default getSkin;
