import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
        metName :{
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			fontSize: '1.25em',
			background : 'rgba(0,0,0,0.6)'
		},
		metNamePopup : {
			color: skin.white,
			fontFamily: skins.fontFamily.timerFamily,
			fontWeight: 600,
			fontSize: '1.25em',
			background : 'rgba(0, 0, 0, 0.7)'
		},
        retroMessage: {
            color: '#114411',
            fontFamily: skins.fontFamily.bodyFamily,
            fontSize: '1em',
            fontWeight: 500,
            lineHeight: '1.375em'
        }
    });
}

export default getSkin;