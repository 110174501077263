import actionConsts from 'constants/actions/actions';

const initialState = {
	blockerList: [],
	isBlockerTutorialReady: false
};

// blockerList: [ {
// 	"id": 14212,
// 	"sprintDay": 4,
// 	"day": 3,
// 	"sprintNumber": 1,
// 	"eventId": 7,
// 	"name": "label_event_7_name",
// 	"description": "label_event_7_description",
// 	"userMetrics": []
// },
// {
// 	"id": 14213,
// 	"sprintDay": 4,
// 	"day": 3,
// 	"sprintNumber": 1,
// 	"eventId": 7,
// 	"name": "label_event_7_name",
// 	"description": "label_event_7_description",
// 	"userMetrics": []
// }],
// isBlockerTutorialReady: tr

const userBlockers = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.INIT_GAME_DATA:
			return {
				...state,
				blockerList: action.payload.userBlockers || []
			};
		case actionConsts.STORE_ACTION_RESPONSE:
			if (action.payload.blockers.length>0 && state.blockerList.length === 0) {
				state = { ...state, isBlockerTutorialReady: true }
			}
			return {
				...state,
				blockerList: state.blockerList.concat(action.payload.blockers)
			};
		case actionConsts.DISABLE_BLOCKER_TUTORIAL:
			return {
				...state,
				isBlockerTutorialReady: false
			}
		default:
			return state;
	}
};

export default userBlockers;