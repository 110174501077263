import React from 'react';
import ReactDOM from 'react-dom';
import { useState,useEffect } from 'react';

const SpotlightPortal = ({ children, targetSelector }) => {
    const [targetElement, setTargetElement] = useState(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const target = document.querySelector(targetSelector);
            if (target) {
                setTargetElement(target);
                clearInterval(intervalId);
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, [targetSelector]);
    
    return targetElement ? ReactDOM.createPortal(children, targetElement) : null;
};

export default SpotlightPortal;