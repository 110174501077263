import React from 'react';
import styles from './stories.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import { checkIfPresent } from 'util/utilFunctions';
import LowPriority from 'svgComponents/lowPriority';
import MediumPriority from 'svgComponents/mediumPriority';
import HighPriority from 'svgComponents/highPriority';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import Must from '../../../../../svgComponents/must/Must';
import Should from '../../../../../svgComponents/should/Should';
import Could from '../../../../../svgComponents/could/Could';
import FeatureRiver from "../../../../../svgComponents/featureRiver/featureRiver.jsx";
import FeatureSwim from "../../../../../svgComponents/featureSwim/featureSwim.jsx";
import FeatureSafety from "../../../../../svgComponents/featureSafety/featureSafety.jsx";
import FeatureOther from "../../../../../svgComponents/featureOther/featureOther.jsx";
import FeatureLounging from "../../../../../svgComponents/featureLounging/featureLounging.jsx";

const priorityMappings = {
	label_must: {
        image: <Must/>,
        text: 'label_must',
        backgroundColor: 'mustColor',
        textColor: '#FFFFFF'
    },
    label_should: {
        image: <Should/>,
        text: 'label_should',
        backgroundColor: 'shouldColor',
        textColor: '#FFFFFF'
    },
    label_could: {
        image: <Could/>,
        text: 'label_could',
        backgroundColor: 'couldColor',
        textColor: '#000000'
    }
};

const Tabs = (props) => {

	const { sprinStories, getLabel } = props;
	const myStyles = getSkin(props.skinGuide);
	//console.log(props)
	const priority = (pri) => {
		const priorityList = props.priorities.prioritiesList;
		const storyPriorityDetails = priorityList.find((eachPriority) => {
			return (eachPriority.priorityId === pri)
		});
	const isArabic = props.userState.lang === "arabic";
	// console.log("lang", isArabic);
	// console.log("props.userState", props.userState);
		switch (pri) {
			case 1:
				return (
					<div styleName={ isArabic ? "priority": "priority_arabic"}>
						<div styleName="priority-image">
							<HighPriority />
						</div>
						<div styleName="priority-letter">
							{props.getLabel(storyPriorityDetails.key)}
						</div>
					</div>
				);

			case 2:
				return (
					<div styleName={ isArabic ? "priority": "priority_arabic"}>
						<div styleName="priority-image">
							<MediumPriority />
						</div>
						<div styleName="priority-letter">
							{props.getLabel(storyPriorityDetails.key)}
						</div>
					</div>
				);
			case 3:
				return (
					<div styleName={ isArabic ? "priority": "priority_arabic"}>
						<div styleName="priority-image">
							<LowPriority />
						</div>
						<div styleName="priority-letter">
							{props.getLabel(storyPriorityDetails.key)}
						</div>
					</div>
				);
			default:
				break;
		}
	}

	const renderFeatureImage = (feature) => {
		switch (feature.imageKey) {
            case 'IMG_FEATURE_RP':
                return <FeatureRiver />;
            case 'IMG_FEATURE_SB':
                return <FeatureSwim />;
            case 'IMG_FEATURE_SS':
                return <FeatureSafety />;
            case 'IMG_FEATURE_LA':
                return <FeatureLounging />;
            case 'IMG_FEATURE_OF':
                return <FeatureOther />;
            default:
                return <img alt="default" src={props.getImage('IMG_DEFAULT_FEATURE')} />;;
        }
	}

	const storiesRenderer = (story) => {
		const priorityInfo = priorityMappings[story.priorityTag] || {};
		const priorityBackgroundColor = props.skinGuide.globalProfiles.palette[priorityInfo.backgroundColor];
		// console.log(props)
		const feature = props.features.featuresList.find(feature => feature.featureId === story.featureId);
		return (
			<div styleName="story" key={story.id}>
				{/* <div styleName="name">{props.getLabel(story.name).substr(0, 40)}</div> */}
				<div styleName="card-tag" className={css(myStyles.cardTag)}>
					<div styleName="card-tag-image">
						{renderFeatureImage(feature)}
					</div>
					<div styleName="card-tag-text" className={css(myStyles.cardTagText)}>
						{props.getLabel(feature.name)}
					</div>
				</div>
				<div styleName="card-name" className={css(myStyles.cardName)}>{props.getLabel(story.name)}</div>
				<div styleName="card-description" className={css(myStyles.cardDesc)}>
					{props.getLabel(story.description).length > 70 ? `${props.getLabel(story.description).slice(0, 70)}...` : props.getLabel(story.description)}
				</div>
				<div styleName="bottom">
					<div styleName="info" className={css(myStyles.info)}>
						{getLabel("label_report_story_points")} | <b>{story.storyPoint}</b>
					</div>
					<div 
					styleName="priority-container" className={css(myStyles.priorityContainer)}
					style={{backgroundColor: priorityBackgroundColor ,
							color: priorityInfo.textColor
							}}
					>
						<div styleName="priority-image-container">
							{/* <img styleName="priority-image" src={props.getImage(priorityInfo.image)}></img> */}
							{priorityInfo.image}
						</div>
						<div styleName="priority-text" className={css(myStyles.priorityText)}>
							{props.getLabel(priorityInfo.text)}
						</div>
					</div>

				</div>
			</div>
		)
	}
	const ele = sprinStories.filter((story) => story.storyStatus !== 4).map((story) => storiesRenderer(story));
	return (
		<div styleName={props.fromPopup ? "wrap-popup" :"wrap"}>
			<div className={css(myStyles.storiesHeading)} styleName="stories-heading">
				{props.getLabel('label_report_unfinished_tasks')}
			</div>
			<div styleName="stories">
				{ele}
			</div>
		</div>
	);
}

export default applyWrappers(Tabs, styles);