import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';

const getSkin = (theme) => {
	const skin = theme.globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		customOption: {
			...myTypography.body2,
			color: skin.black,
			fontWeight: '350',
			fontSize: ' 0.875em',
			lineHeight: '2.083vh',
			cursor: 'pointer',
			':hover': {
				color: skin.white,
				backgroundColor: hexToRgbA(skin.black, 0.8)
			}
		},
		userProfileOptionContainer: {
			backgroundColor: hexToRgbA(skin.white, 1),
			borderRadius: '5px',
			border: `1px solid #989898`,
		},
		useNameContainer: {
			backgroundColor: hexToRgbA(skin.orange, 0.2),
			fontWeight: '600',
		},
		userName: {
			...myTypography.body1,
			color: skin.orange,
			fontWeight: '600',
			fontSize: ' 0.875em',
			lineHeight: '2.083vh',

		},
		tooltipArrowRight: {
		},
		tooltipContent: {
			...myTypography.body1,
			border: `0.1vw solid #989898`,
			backgroundColor: '#ffefe8',
			color: skin.orange,
			fontWeight: '600',
			fontSize: ' 0.875em',
			lineHeight: '2.083vh',
			borderRadius: '4px',
			textTransform: 'none',
		},
	});
}

export default getSkin;