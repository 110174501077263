import React from "react";
import styles from "./simProfileArrow.module.sass";
import applyWrappers from "wrappers/ComponentWrapper";
import { checkIfPresent } from "util/utilFunctions";

const DownArrow = props => {
	const skin = props.skinGuide.globalProfiles.palette;
	let color = skin.grayColor4;
	if (checkIfPresent(props.svgColor)) {
		color = props.svgColor;
	}
	return (
		<div styleName="main-component">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 13 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M-4.00004 2.87974C-4.00004 1.48506 -2.60847 0.518613 -1.3016 1.00567L10.803 5.51687C12.4821 6.14265 12.5543 8.49091 10.9168 9.21861L-1.18785 14.5978C-2.51041 15.1855 -4.00004 14.2174 -4.00004 12.7701L-4.00004 2.87974Z"
					fill="#FFEFE8"
					stroke="#989898"
				/>
			</svg>
		</div>
	);
};

export default applyWrappers(DownArrow, styles);
