import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
    const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

    return StyleSheet.create({
        mvpProgressBarContainer: {
			background: '#FFFFFF',
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 600,
            fontSize: '1em',
            borderRadius: '4px'
		},
        mvpProgressBarContainerPopup: {
			background: '#474747B2',
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 600,
            fontSize: '1em',
            borderRadius: '4px'
		},
        mvpProgressBar: {
            background: '#B5F000',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
        },
        wrap: {
            background: '#474747B2'
        },
        wrapPopup: {
            border: '1px solid #989898',
        },
        legendLabel: {
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 350,
            fontSize: '0.75em',
            color: skin.white
        },
        legendLabelPopup: {
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 350,
            fontSize: '0.75em',
            color: skin.black
        },
        legendLabelBacklog: {
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 600,
            fontSize: '0.875em',
            color: skin.black
        },
        semiDonutValue: {
            fontFamily: skins.fontFamily.titleFamily,
            fontWeight: 600,
            fontSize: '2em',
            color: '#989898'
        },
        backlogItemsDone: {
            fontFamily: skins.fontFamily.bodyFamily,
            fontWeight: 600,
            fontSize: '0.75em',
            color: skin.black
        }
    });
}

export default getSkin;