import React from "react";
import styles from "./stepNext.module.sass";
import applyWrappers from "wrappers/ComponentWrapper";
import { checkIfPresent } from "util/utilFunctions";

const StepNext = props => {
	const skin = props.skinGuide.globalProfiles.palette;
	let color = skin.black;
	if (checkIfPresent(props.svgColor)) {
		color = props.svgColor;
	}
	return (
		<div styleName="main-component">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="24" height="24" rx="12" fill="#EE7748" />
				<path
					d="M15.75 9L18.75 12M18.75 12L15.75 15M18.75 12L5.25 12"
					stroke="black"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
};

export default applyWrappers(StepNext, styles);
