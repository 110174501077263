import React from 'react';
import styles from './retro.module.sass';
import applyWrappers from 'wrappers/ComponentWrapper';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import RetroPoint from '../../../../../svgComponents/retroPoint';

const Retro = (props) => {
	const myStyles = getSkin(props.skinGuide);
	const {retroPoints} = props;
	// const ele = sprinStories.filter((story) => story.storyStatus !== 4).map((story) => storiesRenderer(story));
	return (
		<div id="retro">
			<div 
				styleName="met-name" 
				className={props.fromPopup?css(myStyles.metNamePopup):css(myStyles.metName)}
			>{props.getLabel("label_report_retro")}</div>
			<div styleName={props.fromPopup ? "wrap-popup" :"wrap"}>
				<div styleName="retro-content">
					{retroPoints.map((retroPoint) => {
						return (
							<div styleName="single-retro-point">
								<div styleName="image-container">
									<RetroPoint/>
								</div>	
								<div className={css(myStyles.retroMessage)} styleName="retro-message">
									{props.getLabel(retroPoint.message)}
								</div>	
							</div>
						)
					})}
				</div>
			</div>
		</div>
	);
}

export default applyWrappers(Retro, styles);