import React from "react";
import styles from "./retroPoint.module.sass";
// import getSkin from './skin.js';
import applyWrappers from "wrappers/ComponentWrapper";

const Skill = props => {
	return (
		<div styleName="main-component">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 11 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_4248_44681)">
					<path
						d="M1.5 1.875C1.29375 1.875 1.125 2.04375 1.125 2.25V9.75C1.125 9.95625 1.29375 10.125 1.5 10.125H6.75V8.25C6.75 7.83516 7.08516 7.5 7.5 7.5H9.375V2.25C9.375 2.04375 9.20625 1.875 9 1.875H1.5ZM6.75 11.25H1.5C0.672656 11.25 0 10.5773 0 9.75V2.25C0 1.42266 0.672656 0.75 1.5 0.75H9C9.82734 0.75 10.5 1.42266 10.5 2.25V7.62891C10.5 8.02734 10.343 8.40937 10.0617 8.69062L7.94062 10.8117C7.65937 11.093 7.27734 11.25 6.87891 11.25H6.75Z"
						fill="#114411"
					/>
				</g>
				<defs>
					<clipPath id="clip0_4248_44681">
						<rect width="10.5" height="12" fill="#114411" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default applyWrappers(Skill, styles);
