const metricsKeyMapping = {
	SKILL: 'skill',
	MORALE: 'morale',
	MVP: 'pa',
	CS: 'cs',
	QUALITY: 'quality',
	THROUGHPUT: 'throughput',
	BUDGET: 'budget',
	VELOCITY: 'velocity',
	ACCURACY: 'accuracy',
	EFFICIENCY:'efficiency',
	WAYOFWORKING: 'wofw',
	BUSINESSFOCUS: 'bf',
	PROGRESS: 'progress'
}

export default metricsKeyMapping;