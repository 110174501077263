import { StyleSheet } from 'aphrodite';
import { hexToRgbA } from 'util/styleUtil';
import typography from 'defaults/typography';
import skins from 'defaults/skins'

const getSkin = (theme) => {
	const globalProfiles = theme.globalProfiles;
	const skin = globalProfiles.palette;
	const myTypography = typography(theme);

	return StyleSheet.create({
		cardContainer: {
			border: `1px solid rgba(35, 40, 130, 0.2)`,
			boxShadow: '0px 4px 4px 0px #0000001A',
			background: skin.white,
			borderRadius: '4px'
		},
		cardSelectedContainer: {
			border: `2px solid #972082`,
			boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.09), 0px 3px 4px #972082`,
		},
		cardHeading: {
			...myTypography.body1,
			fontWeight: 600,
			lineHeight: 1.4,
			fontSize: '0.875em'
		},
		cardDescription: {
			fontFamily: skins.fontFamily.bodyFamily,
			fontSize: '0.75em',
			fontWeight: 500
		},
		progressbackground: {
			background: skin.grayColor5
		},
		storyPoints: {
			...myTypography.body2,
			color: skin.yellow
		},
		progressNoBlocker: {
			background: '#0B7F3D'
		},
		progressBlocker: {
			background: '#D63228'
		},
		featureDesc: {
			...myTypography.caption,
			color: skin.grayColor4,
			lineHeight: 1.8
		},
		featureName: {
			...myTypography.caption,
			color: skin.grayColor2,
			fontWeight: 600
		},
		featureContainer: {
			background: skin.grayColor5,
			borderRadius: '5px',
			marginLeft: '5px'
		},
		selectedShadow: {
			boxShadow: `0px 0px 1px 1px ${hexToRgbA('#42BAFF', 0.5)}`
		},
		prioritiesContainer: {
			// background: hexToRgbA(skin.yellow, 0.2)
		},
		prioritiesContainerSelected: {
			// background: hexToRgbA(skin.green, 0.05)
		},
		setPriorityHeading: {
			...myTypography.caption,
			fontFamily: skins.fontFamily.titleFamily,
			color: skin.black,
			fontSize: '0.625em',
			fontWeight: 400
		},
		eachPriority: {
		border: `1px solid ${skin.black}`,
			...myTypography.caption,
			color: skin.black,
			// cursor: 'pointer',
			':hover': {
				background: skin.black,
				color: skin.white
			},
			fontWeight: 350,
			fontSize: '0.625em'
		},
		selectedPriority: {
			border: `1px solid ${skin.black}`,
			...myTypography.caption,
			background: skin.black,
			color: skin.white,
			fontWeight: 350,
			fontSize: '0.625em'
		},
		priorityLetter: {
			...myTypography.caption,
			color: 'black',
			fontWeight: 400,
			fontSize: '0.625em'
		},
		priorityContainer: {
			border: '1px solid #000000'
			// borderRadius: '2px'
		},
		priorityContainer1: {
			borderRadius: '2px',
			fontFamily: skins.fontFamily.bodyFamily,
			fontWeight: 400,
			fontSize: '0.625em'
		},
		blockerTag: {
			borderRadius: '6px'
		},
		borderExecution: {
			border: '2px solid #DADADA'
		},
		redTag: {
			background: '#FFDEE6'
		},
		greenTag: {
			background: '#E7FBEA'
		},
		blockerText: {
			...myTypography.body2,
			fontWeight: 350,
			fontSize: '0.625em'
		},
		redText: {
			color: '#D11021'
		},
		greenText: {
			color: '#0B7F3D'
		},
		borderBlocker: {
			border: `2px solid ${skin.red}`,
			scrollBehavior: 'smooth'
		},
		borderPopUp: {
			border: '2px solid #0B7F3D',
			cursor: 'default'
		},
		noCursor: {
			cursor: 'default'
		},
		cardTagText: {
			...myTypography.card_text
		},
		cardTag: {
			background: skin.grayColor8,
			borderBottomLeftRadius: '2px',
			borderBottomRightRadius: '2px',
		},
		storyPointContainer: {
			fontFamily: skins.fontFamily.titleFamily
		}
	});
}

export default getSkin;