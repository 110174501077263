import React from 'react';
import { connect } from 'react-redux';
import Event from 'components/sprint/execution/event/Event';
import { setUiState } from 'actions/uiState/actionCreators';
import { updateEventsToBeShownCount } from 'actions/events/actionCreators';
import { endSprint } from 'actions/apiAction/endSprint';
import { submitAction } from "actions/apiAction/submitAction";
import {updateStepNumber, pauseTutorial, endTutorial} from "actions/tutorial";

class EventContainer extends React.Component {

    render() {
        return (
            <Event {...this.props} />
        );
    }
}

EventContainer.propTypes = {};

EventContainer.defaultProps = {};

const mapStateToProps = state => {
    return {
        userEvents: state.userEvents,
        metrics: state.metrics,
        uiState: state.uiState,
        userState: state.userState,
        actions: state.actions,
        tutorial: state.tutorial,
        userMetrics: state.userMetrics
    };
};

const mapDispatchToProps = dispatch => ({
    setUiState: (payload) => {
        dispatch(setUiState(payload))
    },
    updateEventsToBeShownCount: (count) => {
        dispatch(updateEventsToBeShownCount(count))
    },
    endSprint: (payload, callback) => {
        dispatch(endSprint(payload, callback));
    },
    submitAction: (actionOptionId, payload, callback) => {
		dispatch(submitAction(actionOptionId, payload, callback));
	},
    updateStepNumber: payload => {
		dispatch(updateStepNumber(payload));
	},
    pauseTutorial: payload => {
		dispatch(pauseTutorial(payload));
	},
    endTutorial: () => {
        dispatch(endTutorial());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventContainer);
