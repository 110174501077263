import React, { useEffect, useState } from 'react';
import styles from './actionResponse.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import FilledButton from "commonComponents/buttons/filledButton";
import ActionResponseMetrics from '../actionResponseMetrics';
import updateRoute from 'util/webAppRoutes/updateRoute';
import { checkIfPresent } from 'util/utilFunctions';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import { over } from 'lodash';

const ActionResponse = (props) => {

	useEffect(()=>{
		if(props.tutorial && (props.tutorial.type === "sprintExecutionScreen" || 
			props.tutorial.type === "sprintEventScreen" || props.tutorial.type === "blockerAction")){
			props.startTutorial()
		}
	},[])

	const myStyles = getSkin(props.skinGuide);

	const latestUserActionResponse = props.userActions.userActionsList[
		props.userActions.userActionsList.length - 1
	];

	const getMetricsvalue = (key, defaultValue = 0, defaultDiff = 0) => {
		const individualUserMetrics = getUserMetricsForMetricsKey(
			props.metrics.metricsList,
			latestUserActionResponse.userMetrics,
			key
		);

		let value = defaultValue;
		let diff = defaultDiff;

		if (individualUserMetrics) {
			value = individualUserMetrics.value;
			diff = individualUserMetrics.diff;
		}

		return { value, diff };
	}

    const checkAndCallEndSprint = (props) => {
        const currentSprintNumber = props.userState.currentSprintNumber;
        props.endSprint(
            {
                sprintId: currentSprintNumber
            },
            () => {
                // if (currentSprintNumber === 1) {
                //     props.setWalkthroughState('executionSprintLaunch')
                // }
                // else {
                //     updateRoute({ route: `/execution/sprint/${currentSprintNumber}/report` })
                // }
				updateRoute({ route: `/execution/sprint/${currentSprintNumber}/report` })
            }
        );
    }

    const redirectToSimReport = (props) => {
        const currentSprintNumber = props.userState.currentSprintNumber || 1;
        if (props.userState.currentSprintState == null || props.userState.isGameCompleted) {
            updateRoute({ route: `/execution/sprint/${currentSprintNumber}/report` })
        }
    }

	const closeActionResponse = () => {
		if(props.tutorial.isRunning){
			if(props.tutorial.type === 'blockerAction'){
				props.stopBlockerTutorial();
			}
			props.endTutorial();
		}
		let updatedUiState = {
			showOverlay: false,
			overlayComponentType: '',
			highlightDesktopHeader: false,
			highlightMetricsHeader: false,
			overlayComponent: ''
		};
		if (props.userEvents.eventsToBeShownCount !== 0) {
			updatedUiState = {
				highlightDesktopHeader: true,
				highlightMetricsHeader: false,
				overlayComponentType: 'CENTER',
				overlayComponent: 'EVENT_LOADER'
			};
		} else if (props.uiState.showReplanSprintPopup) {
			updatedUiState = {
				showOverlay: true,
				highlightDesktopHeader: true,
				highlightMetricsHeader: false,
				overlayComponentType: 'CENTER',
				overlayComponent: 'REPLAN_SPRINT_HELP_POPUP'
			};
		}
		props.setUiState(updatedUiState);
		if(props.userEvents.eventsToBeShownCount === 0){
			if (
				props.userState.currentSprintDay > 15
				&& props.userState.currentSprintState !== 4
			) {
				checkAndCallEndSprint(props);
			} 
			// else if (props.userState.currentSprintState !== props.userState.currentSprintState) {
			// 	checkSprintStateAndRedirect(props);
			// } 
			else if (props.userState.isGameCompleted) {
				redirectToSimReport(props);
			}
		}
	}

	if (props.userActions.userActionsList.length === 0) {
		return (
			<div className={css(myStyles.container, myStyles.responseMessage)} styleName="container">
				No actions taken
			</div>
		);
	}
// to be foixed
	let latestActionOption = props.actions.actionOptionsList.filter(actionOption =>
		actionOption.id === latestUserActionResponse.actionOptionId
	)[0];

	const actionId = latestActionOption? latestActionOption.actionId : latestUserActionResponse.actionId
	const latestAction = props.actions.actionsList.filter(action =>
		action.id === actionId
	)[0];

	if (!latestActionOption) {
		if (latestUserActionResponse.blockerAction)
			latestActionOption = {
				...latestAction,
				...{
					responseImageKey: latestUserActionResponse.blockerAction.responseImageKey
				}
			};

		if(latestUserActionResponse.eventAction) {
			latestActionOption = {
				...latestAction,
				...{
					responseImageKey: latestUserActionResponse.eventAction.responseImageKey
				}
			};
		}
	}

	const wofwDiff = getMetricsvalue('WAYOFWORKING').diff || 0;
	const bfDiff= getMetricsvalue('BUSINESSFOCUS').diff || 0;
	const progressDiff = getMetricsvalue('PROGRESS').diff || 0;

	const overallImpact = wofwDiff + bfDiff + progressDiff;

	let borderColor = '#0B7F3D';
	if(overallImpact<0){
		borderColor = '#D11021';
	}
	else if(overallImpact==0){
		borderColor = '#FBBD0E'
	}
	
	return (
		<div className={css(myStyles.container)} styleName="container"
			 style={{borderTop: `solid 1.042vh ${borderColor}`}}
		>
			<div styleName="action-response-child" id="action-response-container"></div>
			<div styleName="image-container">
				<img
					alt={latestActionOption.responseImageKey}
					src={props.getImage(latestActionOption.responseImageKey)}
					styleName="image"
				/>
			</div>
			<div
				className={css(myStyles.responseContainer)}
				styleName="response-container"
			>
				<div className={css(myStyles.title)}>
					{`${props.getLabel(latestActionOption.name)}: ${props.getLabel(latestActionOption.description)}`}
				</div>
				<div className={css(myStyles.subTitle)}>
					{/* {props.getLabel(latestActionOption.name)} */}
					{/* feedback message to come */}
				</div>
				<div
					className={css(myStyles.responseMessage)}
					styleName="response-message"
				>
					{props.getLabel(latestUserActionResponse.message)}
				</div>
				{/* <div
					className={css(myStyles.personName)}
					styleName="person-name"
				>
					{`- ${props.getLabel("label_cpo_name")}, ${props.getLabel(
						"label_cpo_pos"
					)}`}
				</div> */}
				<ActionResponseMetrics
					latestActionOption={latestActionOption}
					latestUserActionResponse={latestUserActionResponse}
					metrics={props.metrics}
					userState={props.userState}
					prds={props.prds}
					userPrds={props.userPrds}
					userMetrics={props.userMetrics}
				/>
			</div>
			<div styleName="close-container">
				<FilledButton
					textLabel={props.getLabel("label_continue")}
					clickFunction={closeActionResponse}
					customStyle={{
						width: '50px'
					}}
				/>
			</div>
		</div>
	);
}

export default applyWrappers(ActionResponse, styles);