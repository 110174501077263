import React, { useState } from 'react';
import styles from './sprintReport.module.sass';
import getSkin from './skin.js';
import applyWrappers from 'wrappers/ComponentWrapper';
import Tabs from './tabs/index';
import Metrics from './metric/Metric';
import Stories from './stories/';
import Retro from './retro/Retro.jsx';
import Comments from "./cust/";
import Completion from "./completion/";
import { css } from 'aphrodite/no-important';
import GameComplete from 'svgComponents/gameComplete';
import SprintFooter from '../sprintFooter/SprintFooter.jsx';
import WalkthroughTabs from 'commonComponents/walkthrough/walkthroughTabs';
import { walkthroughTabsList } from 'config/walkthrough';
import {
	showFeedbackFormWithoutPopup,
} from 'util/feedback';
import HollowButton from 'commonComponents/buttons/hollowButton';
import FilledButton from 'commonComponents/buttons/filledButton';
import { getUserMetricsForMetricsKey } from 'util/utilFunctions';
import History from 'components/metricsHeader/history';
import InfoComponent from 'commonComponents/infoComponent';
import SendEmailReport from 'commonComponents/sendEmailReport';
import analyticsUtil from 'util/segmentUtil';
import GameIncomplete from '../../../../svgComponents/gameIncomplete/GameIncomplete.jsx';
import SprintReportPopupHeader from '../../../../commonComponents/sprintReportPopupHeader/SprintReportPopupHeader.jsx'

const downloadReport = (props) => {
	//Segment integration
	analyticsUtil.track('Report_Download', props.userDetails);
	props.setIsDownloading(true);
	props.fetchReportLink();
}

const SprintReportPopup = (props) => {
	console.log("tutorial", props.tutorial);

	const myStyles = getSkin(props.skinGuide);
	const {
		userState,
		metrics,
		userSprintReports,
		getLabel,
		stories,
		report,
		fromPopup,
	} = props;
	const { isGameCompleted } = userState;

    const [selected, setSelected] = useState(!fromPopup ? props.userState.currentSprintNumber : 1);
	const [ disableStartButton, setDisableStartButton ] = useState(false);

	const setFeedbackCompleted = () => {
		props.setUserState({
			isFeedbackSubmitInProgress: false,
			isKfeedbackSubmitted: true
		});
	}

	const startNextSprint = () => {
		if (props.tutorial.isRunning) props.pauseTutorial();
		setDisableStartButton(true);
		props.startSprint(
			{
				sprintId: props.userState.currentSprintNumber
			}
		);
	}

	let sprintReport = userSprintReports.sprintReportList.find((eachReport) => {
		return eachReport.sprintNumber === selected;
	});

	const mvpUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		props.userMetrics.userMetricsList,
		'MVP'
	);
	const skillUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		sprintReport.reportMetrics,
		'SKILL'
	);
	const moraleUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		sprintReport.reportMetrics,
		'MORALE'
	);
	const csUserMetrics = getUserMetricsForMetricsKey(
		props.metrics.metricsList,
		sprintReport.reportMetrics,
		'CS'
	);

	const skillChangeInMetric = Math.round(skillUserMetrics.changeInMetricValue);
	const moraleChangeInMetric = Math.round(moraleUserMetrics.changeInMetricValue);
	const csChangeInMetric = Math.round(csUserMetrics.changeInMetricValue);

	const skillChange = skillChangeInMetric >=0 ? skillChangeInMetric == 0 ? 'neutral' :'positive' : 'negative';
	const moraleChange = moraleChangeInMetric >=0 ? moraleChangeInMetric == 0 ? 'neutral' :'positive' : 'negative';
	const csChange = csChangeInMetric >=0 ? csChangeInMetric == 0 ? 'neutral' :'positive' : 'negative';

	const skillChangeLabel = `label_report_speak_sprint_${selected}_ts_change_${skillChange}`;
	const moraleChangeLabel = `label_report_speak_sprint_${selected}_tm_change_${moraleChange}`;
	const csChangeLabel = `label_report_speak_sprint_${selected}_cs_change_${csChange}`;



	const updatedWalkthroughTabsList = walkthroughTabsList.map(walkthroughTab => {
		// if (walkthroughTab.key === 'launch') {
		// 	return {
		// 		...walkthroughTab,
		// 		isSelected: true,
		// 		isCompleted: false
		// 	};
		// }
		return {
			...walkthroughTab,
			isSelected: false,
			isCompleted: true
		};
	});


	var totalSprints
	if (fromPopup) {
		totalSprints = props.userState.currentSprintNumber - 1
	} else {
		totalSprints = props.userState.currentSprintNumber
	}


	// if (!sprintReport) {
	// 	let len = userSprintReports.sprintReportList.length
	// 	sprintReport = userSprintReports.sprintReportList[len - 1]
	// 	setSelected(sprintReport.sprintNumber)
	// }

	// const messagesReport = sprintReport.reportSpeak.reportSpeakMessageArray.map((block) => getLabel(block.metricMessage));

	const reportStories = sprintReport.reportStories;

	const sprinStories = reportStories.map((us) => {
		const st = stories.storyList.find((str) => str.id === us.storyId);
		return { ...st, ...us }
	});

	const notCompletedSprintStories = sprinStories.filter((story) => story.storyStatus != 4);

	return (
		<div styleName="report-info-container"
		style={fromPopup?{marginTop: 0}:null}>
            <SprintReportPopupHeader {...props}/>
            <div styleName="current-sprint-container" className={css(myStyles.tabsPopup)}>
                <Tabs sprintCount={totalSprints} selected={selected} setSelected={setSelected} getLabel={getLabel} fromPopup={true}/>
				{!fromPopup && !props.userState.isGameCompleted?
					<FilledButton
						textLabel={props.getLabel('label_start_next_sprint')}
						clickFunction={startNextSprint}
						disableButton={false}
					/>
				: 
				null}
            </div>
            <div styleName="report-content">
                <div styleName="text" className={css(myStyles.text)}>
                    <div className={css(myStyles.textHeading)}>{`${getLabel('label_sprint_report_main_heading')} Sprint ${selected}!`}</div>
                    <div className={css(myStyles.textPara)}>
                        {`Sprint ${selected} ${getLabel('label_report_speak_heading')} ${props.getLabel(csChangeLabel)} ${props.getLabel(skillChangeLabel)} ${props.getLabel(moraleChangeLabel)}`}
                    </div>
                    <div styleName="footer-svg1"
                    style={{zIndex: 0, left: 0, width: '40.556vw', bottom: '4.5vh'}}>
                        <svg viewBox="0 0 554 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.1" d="M-10 54.2592V0C-10 0 84.2841 64.452 230.498 16.2051C376.712 -32.0418 553.583 54.2592 553.583 54.2592H-10Z" fill="white"/>
                        </svg>
                    </div>
                    <div styleName="footer-svg2"
                    style={{zIndex: 0, left: '30vw', width: '74.223vw', bottom: '0vh'}}>
                        <svg viewBox="0 0 798 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.1" d="M1014.58 54.6552V0C1014.58 0 774.224 56.2693 472.845 20.6246C171.465 -15.0201 0.687988 54.6552 0.687988 54.6552H1014.58Z" fill="white"/>
                        </svg>
                    </div>
                    <div styleName="footer-svg3"
                    style={{zIndex: 0, right: '0vw', width: '25.476vw', bottom: '-1vh'}}>
                        <svg viewBox="0 0 348 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.1" d="M564.241 54.2592V0C564.241 0 469.957 64.452 323.743 16.2051C177.529 -32.0418 0.65863 54.2592 0.65863 54.2592H564.241Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div styleName="info">
                    <div styleName="info-left-container">
                        <div styleName="metrics">
                            <Metrics sprintReport={sprintReport} userMetrics={sprintReport.reportMetrics} userState={userState} metrics={metrics} selected={selected} getLabel={getLabel} fromPopup={true}/>
                        </div>
                        <div styleName="task-graph unfinshed-task oa" id="unfinished-container">
                            <div styleName="heading" className={css(myStyles.metNamePopup)}>
                                {getLabel("label_report_task_completion")}
                            </div>
                            <div styleName="unfinished-container">
                                <Completion sprinStories={sprinStories} getLabel={getLabel} fromPopup={true}/>
                                <div styleName="task">
                                    <div styleName="task-row">
                                        
                                        {
                                            notCompletedSprintStories.length > 0
                                                ? <div styleName="unfinshed-task">
                                                    <Stories sprinStories={notCompletedSprintStories} getLabel={getLabel} {...props} fromPopup={true}/>
                                                </div>
                                                : null
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {fromPopup ? null :<div className={css(myStyles.verticalBar)} styleName='vertical-bar'></div>} */}
                    <Retro {...props} retroPoints={sprintReport.retroPoints} fromPopup={true}/>
                </div>
            </div>
        </div>
	);
}

export default applyWrappers(SprintReportPopup, styles);