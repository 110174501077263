import React, { useState, useEffect } from 'react';
import styles from './simUserProfile.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import UserProfile from 'svgComponents/userProfile';
import UpArrow from 'svgComponents/upArrow';
import DownArrow from 'svgComponents/downArrow';
import urls from 'constants/urls/urls';
import { getUserName } from 'util/utilFunctions';
import analyticsUtil from 'util/segmentUtil';
import ConfirmLogout from './confirmLogout/ConfirmLogout.jsx';
import InfoComponent from 'commonComponents/infoComponent/InfoComponent';
import SimProfileArrow from '../../../svgComponents/simProfileArrow/SimProfileArrow.jsx';

const userProfileRef = React.createRef();

const SimUserProfile = (props) => {
	const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
	const [isUserProfileOnHover, setIsUserProfileOnHover] = useState(false);
	const [isLogoutOpen, setIsLogoutOpen] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);

	const userName = getUserName(props.userDetails);

	useEffect(() => {
		if (isUserProfileOpen) {
			document.addEventListener('mousedown', handleClick, false);
		} else {
			document.removeEventListener('mousedown', handleClick, false);
		}
		return () => {
			document.removeEventListener('mousedown', handleClick, false);
		}
	});

	const handleClick = (e) => {
		setShowTooltip(false);
		if (userProfileRef.current.contains(e.target)) {
			//do nothing
			return;
			}
		setIsUserProfileOpen(false);
	}

	const onClickLogout = () => {
		setIsUserProfileOpen(false);
		setIsLogoutOpen(true);
	}

	const confirmLogout = () => {
		setIsUserProfileOpen(false);
		setIsLogoutOpen(false);

		analyticsUtil.reset();
		analyticsUtil.track("LOGOUT", { userDetails: props.userDetails });

		window.location.href = urls.LOG_OUT;
	}

	const cancelLogout = () => {
		setIsLogoutOpen(false);
		setIsUserProfileOpen(false);
	}

	const toggleUserProfile = () => {
		if(!isLogoutOpen){
			setIsUserProfileOpen(!isUserProfileOpen);
		}
	}

	const hoverProfile = () => {
		return (
			<div styleName="tooltip-container-bottom">
				<div className={css(myStyles.tooltipContent)} styleName="tooltip-content">
					{`${props.getLabel('label_hello')} @${(userName.toLowerCase())}`}
					<div styleName="tooltip-arrow-right" className={css(myStyles.tooltipArrowRight)}>
						<SimProfileArrow/>
					</div>
				</div>
			</div>
		);
	}

	const myStyles = getSkin(props.skinGuide);
	const skin = props.skinGuide.globalProfiles.palette;
	return (
		<>
			<div
				styleName="user-profile-container-with-options"
				onMouseEnter={() => { setIsUserProfileOnHover(true); }}
				onMouseLeave={() => { setIsUserProfileOnHover(false); }}
				onClick={() => {
					toggleUserProfile();
				}}
				ref={userProfileRef}
			>
				{isUserProfileOpen && (
					<div style={{
						position: 'fixed',
						top: "1vh",
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.7)',
						zIndex: 10,
						filter: 'blur(4px)'
					}} />
				)}

				<div styleName="user-profile-container" style={{ position: 'relative', zIndex: 1 }}>
					<div styleName="user-profile-image-container">
						<UserProfile
							svgColor={isUserProfileOnHover || isUserProfileOpen ? skin.orange : skin.white}
						/>
					</div>
					<div styleName="user-profile-arrow-container">
						{
							isUserProfileOpen
								? <UpArrow
									svgColor={isUserProfileOnHover || isUserProfileOpen ? skin.grayColor5 : skin.white}
								/>
								: <DownArrow
									svgColor={isUserProfileOnHover || isUserProfileOpen ? skin.grayColor5 : skin.white}
								/>
						}
					</div>
				</div>
				{
					isUserProfileOpen
						? <div className={css(myStyles.userProfileOptionContainer)} styleName="user-profile-option-container">
						<div
							onMouseOver={() => { setShowTooltip(true) }}
							onMouseOut={() => { setShowTooltip(false) }}
						>
						<div styleName="user-name-container" className={css(myStyles.useNameContainer)} >
									<div className={css(myStyles.userName)} styleName="user-name">
										{`${props.getLabel('label_hello')} @${(userName.length > 16 ? userName.toLowerCase().substring(0, 16) + '.....' : userName.toLowerCase())}`}
									</div>
									{showTooltip && (userName.length > 16) ? hoverProfile() : null}
								</div>
							</div>
							{
								props.userState.returnType.toLowerCase() === 'normal'
									? <div
										className={css(myStyles.customOption)}
										styleName="each-option"
										onClick={onClickLogout}
									>
										{props.getLabel('label_sign_out')}
									</div>
									: <div
										className={css(myStyles.customOption)}
										styleName="each-option"
										onClick={onClickLogout}
									>
										{props.getLabel('label_return')}
									</div>
							}
						</div>
						: null
				}
			</div>
			{isLogoutOpen && (
				<ConfirmLogout
					onConfirm={confirmLogout}
					onCancel={cancelLogout}
					{...props}
				/>
			)}
		</>
	);
}

export default applyWrappers(SimUserProfile, styles);
